import React, { useState } from "react";
import { Modal, Notification } from "../../components";
import { SERVER_URL } from "../../utils/constant";

const JoinForm = () => {
  const [openModal, setOpenModal] = useState(false);
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [profession, setProfession] = useState("");
  const [country, setCountry] = useState("");
  const [expectations, setExpectations] = useState("");
  const [areas, setAreas] = useState("");
  const [comments, setComments] = useState("");
  const [contactnumber, setContactNumber] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedAgeRange, setSelectedAgeRange] = useState("");
  const [selectedReferredBy, setSelectedReferredBy] = useState("");
  const [whereSawAd, setWhereSawAd] = useState("");

  const eventOptions = [
    "Voluntary Outreach Programmes",
    "Networking Event",
    "Entertainment Event",
    "Seminars/Conferences",
    "Investment opportunities",
    "All of the above",
    "Other",
  ];

  const ageRangeOptions = ["16-20", "20-30", "30-40", "40-50", "40-60", "60+"];

  const referredByOptions = [
    "GIPC - Ghana Investment Promotion Center",
    "Dentaa Amoateng MBE",
    "Fidelity Bank",
    "Jennifer Djan-Krofa",
    "Other",
  ];

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedEvents((prevSelectedEvents) => [...prevSelectedEvents, value]);
    } else {
      setSelectedEvents((prevSelectedEvents) =>
        prevSelectedEvents.filter((event) => event !== value)
      );
    }
  };

  const handleAgeRangeChange = (event) => {
    setSelectedAgeRange(event.target.value);
  };

  const handleReferredByChange = (event) => {
    setSelectedReferredBy(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitButtonValue("Sending");

    // Create a FormData object
    const formData = new FormData();
    formData.append("fullname", name);
    formData.append("email", email);
    formData.append("profession", profession);
    formData.append("country", country);
    formData.append("expectations", expectations);
    formData.append("eventOption", selectedEvents);
    formData.append("areas", areas);
    formData.append("comments", comments);
    formData.append("contactnumber", contactnumber);
    formData.append("whereSawAd", whereSawAd);
    formData.append("ageRangeOption", selectedAgeRange);
    formData.append("referredByOption", selectedReferredBy);

    fetch(`${SERVER_URL}/submit-form`, {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);

        if (data.success) {
          // Log form data values
          // formData.forEach((value, key) => {
          //   console.log(key, value);
          // });
          console.log("Form submitted successfully!");

          setNotificationMessage(data.message);
          setNotificationType("success");

          setShowNotification(true);

          // Close the notification after 3 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);

          // window.location.href = "/payments";
          window.location.assign("/pricing");

          // Show the payment modal after successful form submission
          // setOpenModal(true);
        } else {
          console.error("Form submission failed:", data.error);

          setNotificationMessage(
            data.message || "Form submission failed. Please try again."
          );
          setNotificationType("error");

          // Show the notification
          setShowNotification(true);
        }

        setSubmitButtonValue("Submit");
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");

        // Show the notification
        setShowNotification(true);
        setSubmitButtonValue("Submit");
      });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label for="FullName">Full Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="name"
          name="name"
          required="required"
        />
        <label for="Profession">Email Address</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          name="email"
          required
        />

        <label for="Profession">Profession</label>
        <input
          type="text"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
          id="profession"
          name="profession"
          required
        />
        <label for="Country">
          What Country Did you relocate from or planning to relocate from please
          also mention the state? Ie New York/London/Toronto
        </label>
        <textarea
          type="text"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          id="country"
          name="country"
        ></textarea>
        <label for="Expectations">
          What do you hope to get out of The GUBA Diaspora Network?
        </label>
        <textarea
          type="text"
          value={expectations}
          onChange={(e) => setExpectations(e.target.value)}
          id="expectations"
          name="expectations"
        ></textarea>
        {/* Form Group (Group Selection Checkboxes) */}
        <div className="mb-3">
          <label className="small mb-1">
            What type of events would you want organized for Diasporans such as
            yourself in Ghana?
          </label>
          {eventOptions.map((eventOption) => (
            <div className="form-check" key={eventOption}>
              <input
                className="form-check-input"
                id={`eventCheckbox_${eventOption}`}
                type="checkbox"
                name="eventOption"
                value={eventOption}
                checked={selectedEvents.includes(eventOption)}
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label"
                htmlFor={`eventCheckbox_${eventOption}`}
              >
                {eventOption}
              </label>
            </div>
          ))}
        </div>
        <br />
        <label for="areasWithinGDN">
          What areas would you like to help with, within The GUBA Diaspora
          Network?
        </label>
        <textarea
          type="text"
          value={areas}
          onChange={(e) => setAreas(e.target.value)}
          id="areas"
          name="areas"
        ></textarea>
        <br />
        <label for="comments">
          Please share any other comments you have below:
        </label>
        <textarea
          type="text"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          id="comments"
          name="comments"
        ></textarea>
        <label for="contactnumber">Contact Number (add area code)</label>
        <textarea
          type="tel"
          value={contactnumber}
          onChange={(e) => setContactNumber(e.target.value)}
          id="contactnumber"
          name="contactnumber"
          required
        ></textarea>
        <label for="whereSawAd">
          Where did you hear about us? Or see our advert?
        </label>
        <textarea
          type="text"
          value={whereSawAd}
          onChange={(e) => setWhereSawAd(e.target.value)}
          id="whereSawAd"
          name="whereSawAd"
        ></textarea>
        {/* Form Group (Group Selection Checkboxes) */}
        <div className="mb-3">
          <label className="small mb-1">What is your age range</label>
          {ageRangeOptions.map((ageRangeOption) => (
            <div className="form-check" key={ageRangeOption}>
              <input
                className="form-check-input"
                type="radio"
                id="ageRangeOption"
                name="ageRangeOption"
                value={ageRangeOption}
                checked={selectedAgeRange === ageRangeOption}
                onChange={handleAgeRangeChange}
              />
              <label className="form-check-label" htmlFor="ageRangeCheckBox">
                {ageRangeOption}
              </label>
            </div>
          ))}
        </div>
        {/* Form Group ( Selection) */}
        <div className="mb-3 col-md-6">
          <label className="small mb-1" htmlFor="referredBy">
            Referred by
          </label>
          <select
            className="form-control"
            aria-label="Default select example"
            name="referredByOption"
            id="referredByOption"
            value={selectedReferredBy}
            onChange={handleReferredByChange}
          >
            <option value="" disabled>
              Select
            </option>
            {referredByOptions.map((referredByOption) => (
              <option key={referredByOption} value={referredByOption}>
                {referredByOption}
              </option>
            ))}
          </select>
        </div>
        <br />
        <input type="submit" className="btn3" value={submitButtonValue} />
      </form>

      {/* Render the PaymentModal conditionally */}
      {/* {openModal && <Modal closeModal={setOpenModal} />} */}

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default JoinForm;
