import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";
import "./policy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="policy pt-8">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div className="policyContentHeader">
              <h1>Privacy Policy</h1>
              {/* <small>Last Updated On 29-Dec-2022</small> */}
              {/* <small>Effective Date 28-Dec-2022</small> */}
            </div>
            <div className="policyContent">
              <article>
                <p>
                  This policy applies between you, the user of this website and
                  The Guba Diaspora Network, the owner and provider of this
                  website. This policy applies to our use of any and all data
                  collected by us in relation to your use of the website and any
                  services or systems therein.
                </p>
              </article>
              <article>
                <h4>(Section 1) What Information Do We Collect?</h4>
                <div>
                  <ul>
                    <li>Name</li>
                    <li>
                      Contact information such as email addresses and telephone
                      numbers
                    </li>
                    <li>
                      {" "}
                      Demographic information such as post code, preferences and
                      interests
                    </li>
                    <li>Date of birth</li>
                    <li>gender</li>
                    <li>job title</li>
                    <li>profession</li>
                    <li>IP address (automatically collected)</li>
                    <li>
                      Web browser type and version (automatically collected)
                    </li>
                    <li> Operating system (automatically collected)</li>
                  </ul>
                </div>
              </article>
              <article>
                <p>
                  A list of urls starting with a referring site, your activity
                  on this website, and the site you exit to (automatically
                  collected). We also collect website usage information. We do
                  this using cookies (see section 6 below).
                </p>
                <div>
                  <h4>(Section 2) How do we use your information?</h4>
                  <p>
                    Any personal data you submit will be retained by The Guba
                    Diaspora Network for as long as you use the services and
                    systems provided on the website. Data that you may submit
                    through any communications system that we may provide may be
                    retained for a longer period of up to 3 months.
                  </p>
                  <p>
                    Unless we are obliged or permitted by law to do so, and
                    subject to (Section 3) your data will not be disclosed to
                    third parties. This includes our affiliates and / or other
                    companies within our group.
                  </p>
                  <p>
                    All personal data is stored securely in accordance with the
                    principles of the data protection act 1998. For more details
                    on security, see section below.
                  </p>
                  <p>
                    Any or all of the above data may be required by us from time
                    to time in order to provide you with the best possible
                    service and experience when using our website. Specifically,
                    data may be used by us for the following reasons:
                    <ul>
                      <li>Internal record keeping</li>
                      <li>
                        To provide you via email, with The Guba Diaspora Network
                        related information, products and services you ask for,
                        and honour any contract you have with us
                      </li>
                      <li>To improve your user experience</li>
                      <li>
                        For statistical purposes when we evaluate our range of
                        services
                      </li>
                      <li>To personalise repeat visits to our website</li>
                      <li>To manage member service queries</li>
                    </ul>
                  </p>
                  <p>
                    The Guba Diaspora Network from time to time, employ the
                    services of other parties for dealing with matters that may
                    include, but are not limited to, payment handling, delivery
                    of purchased items, search engine facilities, advertising
                    and marketing. The providers of such services do not have
                    access to certain personal data provided by users of this
                    website. Any data used by such parties is used only to the
                    extent required by them to perform the services that the
                    Diaspora Transition Network requests. Any use for other
                    purposes is strictly prohibited. Furthermore, any data that
                    is processed by third parties must be processed within the
                    terms of this policy and in accordance with the Data
                    Protection Act 1998.
                  </p>
                </div>
                <div>
                  <h4>(section 3) controlling access to your data</h4>
                  <ul>
                    <li>Use of data for direct marketing purposes</li>
                    <li>Sharing data with third parties</li>
                    <li>Your right to withhold information</li>
                  </ul>
                  <p>
                    You may access certain areas of the website without
                    providing any data at all. However, to use all services and
                    systems available on the website you may be required to
                    submit account information or other data.
                  </p>
                  <p>
                    You may restrict your internet browser’s use of cookies. For
                    more information, see (section 6) below.
                  </p>
                </div>
                <div>
                  <h4>(Section 4) Accessing your own data</h4>
                  <p>
                    You may access your account at any time to view or amend the
                    data. You may need to modify or update your data if your
                    circumstances change. Additional data as to your marketing
                    preferences may also be stored and you may change this at
                    any time.
                    <br />
                    <br />
                    You have the right to ask for a copy of your personal data
                    on payment of a small fee
                  </p>
                </div>
                <div>
                  <h4>(Section 5) Security</h4>
                  <p>
                    Data security is of great importance to The Guba Diaspora
                    Network and to protect your data we have put in place
                    suitable physical, electronic and managerial procedures to
                    safeguard and secure data collected online.
                  </p>
                </div>
                <div>
                  <h4>(Section 6) Cookies</h4>
                  <p>
                    The Guba Diaspora Network may set and access cookies on your
                    computer. All cookies used by the website are used in
                    accordance with the provisions of the privacy and Electronic
                    Communications (EC Directive) regulations 2003 as amended by
                    the Privacy and Electronic Communications (EC directive)
                    (amendment) regulations 2011. The Guba Diaspora Network has
                    carefully chosen these cookies and uses them to facilitate
                    certain functions and features of the website. We also use
                    cookies for analytics purposes. These cookies track your
                    movements and activities on the website and are designed to
                    give us a better understanding of our users, thus enabling
                    us to improve the website and our services.
                  </p>
                  <p>
                    Certain features of the website depend upon cookies to
                    function and are deemed, within the law, to be strictly
                    necessary. You will not be asked for your consent to place
                    these cookies however you may still disable cookies via your
                    web browser’s settings.
                  </p>
                  <p>
                    You can choose to enable or disable cookies in your web
                    browser. By default, your browser will accept cookies,
                    however this can be altered. For further details, please
                    consult the help menu in your browser. Disabling cookies may
                    prevent you from using the full range of services available
                    on the web site.
                  </p>
                  <p>
                    You may delete cookies at any time however you may lose any
                    information that enables you to access the website more
                    quickly.
                  </p>
                  <p>
                    It is recommended that you ensure that your internet browser
                    is up-to-date and that you consult the help and guidance
                    provided by the developer of your browser if you are unsure
                    as to how to adjust your privacy settings.
                  </p>
                </div>
                <div>
                  <h4>(Section 7) Changes to this policy</h4>
                  <p>
                    The Guba Diaspora Network reserves the right to change this
                    privacy policy as we may deem necessary from time to time or
                    as may be required by law. Any changes will be immediately
                    posted on the web site and you are deemed to have accepted
                    the terms of the policy on your first use of the web site
                    following the alterations.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default PrivacyPolicy;
