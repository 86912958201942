import { defineField, defineType } from "sanity";
import { BiCarousel } from "react-icons/bi"

export default defineType({
    name: "homeSlider",
    title: "Home Slider",
    type: "document",
    icon: BiCarousel,
    fields: [
        defineField({
            name: "title",
            title: "Title",
            type: "string",
            description: "The title of the slide"
        }),
        defineField({
            name: "description",
            title: "Description",
            type: "text",
            rows: 4,
            description: "The description of the slide"
        }),
        defineField({
            name: "backgroundVideo",
            title: "Slide Background",
            type: "reference",
            description: "This is the video that will be used as the background for the slide.\nTake note that this video will be muted and static.",
            to: [{ type: "videoGallery" }]
        }),
        defineField({
            name: "aboutText",
            title: "About Text",
            type: "string",
            description: "The text that will be displayed on the button right below the description.\nThis text will be used as the link to the 'About Us' page.",
            validation: Rule => Rule.required(),
            initialValue: "ABOUT US"
        })
    ]
});