import React, { useEffect, useState } from "react";
import "./slider.css";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";

const Slider = ({ sliders }) => {
  const [slideImages, setSlideImages] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"]{ firstSlideImages[]-> }`)
      .then((data) => {
        setSlideImages(data[0].firstSlideImages);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("images slide: ", slideImages);
  }, [slideImages]);

  return (
    <>
      {slideImages?.length > 0 ? (
        <div className="slider">
          <div className="slide-track">
            {slideImages.map((slide, index) => (
              <div className="slide" key={index}>
                <img src={urlFor(slide.image)} alt="slide images" />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Slider;
