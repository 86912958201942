import React, { useState, useCallback, useEffect } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import { galleryImages } from "../../constants/data";
// import { photos } from "./photos";

import "./gallery.css";

const ImagesGallery = ({ galleryImages }) => {
  // Define the image limit and the number of images to display initially
  const imageLimit = 15;
  const [displayedImages, setDisplayedImages] = useState();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const loadMoreImages = () => {
    const nextBatch = galleryImages
      ?.slice(displayedImages?.length, displayedImages?.length + imageLimit)
      .map((img) => ({
        src: img?.url,
        width: 4,
        height: 3,
      }));

    setDisplayedImages((prevImages) => [...prevImages, ...nextBatch]);
  };
  // Determine if there are more images to load
  const hasMoreImages = displayedImages?.length < galleryImages?.length;

  useEffect(() => {
    console.log("gallery: ", galleryImages);
    const initialImages = galleryImages?.slice(0, imageLimit).map((img) => ({
      src: img.url,
      width: 4,
      height: 3,
    }));

    setDisplayedImages(initialImages);
  }, [galleryImages]);

  return (
    <div className="gallery-container">
      {displayedImages?.length > 0 && (
        <Gallery photos={displayedImages} onClick={openLightbox} />
      )}
      {hasMoreImages && (
        <button className="btn-primary-variant" onClick={loadMoreImages}>
          Load More
        </button>
      )}
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={displayedImages?.map((photo) => ({
                source: {
                  regular: photo.src,
                },
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

export default ImagesGallery;
