import React, { useEffect, useState } from "react";
import "./services.css";
import { SKParagraph, ScrollToTop } from "../../components";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../../utils/constant";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import Skeleton from "react-loading-skeleton";

const Services = () => {
  const [servicesData, setServicesData] = useState({});

  const handleDownload = () => {
    window.open(`${SERVER_URL}/public/GDN SERVICES.pdf`, "_blank");
  };

  useEffect(() => {
    sanityClient.fetch(`*[_type == "services"]`).then((data) => {
      setServicesData(data[0]);
      console.log(data);
    });
  }, []);

  useEffect(() => {
    console.log("servicesData", servicesData);
  }, [servicesData]);

  return (
    <>
      <section id="services">
        <div className="container">
          {servicesData?.description ? (
            <BlockContent blocks={servicesData?.description} />
          ) : (
            <>
              <p>
                <SKParagraph />
              </p>
              <p>
                <SKParagraph />
              </p>
              <p>
                <SKParagraph />
              </p>
            </>
          )}

          <div className="link-row">
            <Link to="/joinus">Click here to join</Link>
            <a className="btn-link" onClick={handleDownload}>
              Download Services PDF
            </a>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Services;
