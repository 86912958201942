import React from "react";
import "./cards.css";

import { icon1, icon2, icon3, icon4, icon5, icon6 } from "../../assets";
import Skeleton from "react-loading-skeleton";
import SKMissionCards from "../Skeletons/SKMissionCards";
import { urlFor } from "../../utils/urlFor";

const cardStatements = [
  {
    id: "1",
    icon: icon1,
    text: "To support individuals and organisations seeking to connect, transition, or do business and invest in Ghana ",
  },
  {
    id: "2",
    icon: icon2,
    text: "To generate solutions to the challenges faced by the diaspora in Ghana ",
  },
  {
    id: "3",
    icon: icon3,
    text: "To promote businesses of members to our diasporan community ",
  },
  {
    id: "4",
    icon: icon4,
    text: "To create a platform for information sharing and networking ",
  },
  {
    id: "5",
    icon: icon5,
    text: " To build a skills database of talents from across the world ",
  },
  {
    id: "6",
    icon: icon6,
    text: " To form a community of impact and give back to the motherland ",
  },
];

const MissionCards = ({ missionCards }) => {
  return missionCards?.length > 0 ? (
    <div id="mscards">
      <div class="vmStatements">
        <div class="statements">
          {missionCards.map((item, id) => (
            <div class="statementBars" key={id}>
              <div class="iconBox">
                <img src={urlFor(item.icon)} alt="handshake" />
              </div>
              <div class="txtBox">
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <SKMissionCards />
  );
};

export default MissionCards;
