import {
  slideImg1,
  slideImg10,
  slideImg2,
  slideImg3,
  slideImg4,
  slideImg5,
  slideImg6,
  slideImg7,
  slideImg8,
  slideImg9,
  slide2Img1,
  slide2Img10,
  slide2Img2,
  slide2Img3,
  slide2Img4,
  slide2Img5,
  slide2Img6,
  slide2Img7,
  slide2Img8,
  slide2Img9,
  gubaSlide2Img1,
  gubaSlide2Img2,
  gubaSlide2Img3,
  gubaSlide2Img4,
  gubaSlide2Img5,
  gubaSlide2Img6,
  gubaSlide2Img7,
  gubaSlide2Img8,
  gubaSlide2Img9,
  spon1,
  spon2,
  spon3,
  spon4,
  spon5,
  spon6,
  spon7,
  spon8,
  spon9,
  oldSch,
  dtc,
  gubaRetreat,
  odo,
  meetUp,
  gubaAwards23,
  afriExpo23,
  con1,
  con2,
  con3,
  con4,
  con5,
  con6,
  con17,
  con8,
  con9,
  con10,
  con11,
  con12,
  con13,
  con14,
  con15,
  con16,
  con18,
  con19,
  con20,
  con21,
  con22,
  con23,
  con24,
  con25,
  con26,
  con27,
  con28,
  con29,
  con30,
  odo1,
  odo2,
  odo3,
  odo4,
  odo5,
  odo6,
  odo7,
  odo8,
  odo9,
  odo10,
  odo11,
  odo12,
  odo13,
  odo14,
  odo15,
  odo16,
  odo17,
  odo18,
  odo19,
  odo20,
  odo21,
  odo22,
  odo23,
  odo24,
  odo25,
  odo26,
  odo27,
  odo28,
  oldSch1,
  oldSch3,
  oldSch5,
  oldSch7,
  oldSch9,
  oldSch11,
  oldSch13,
  oldSch15,
  oldSch17,
  oldSch19,
  oldSch21,
  oldSch23,
  odo29,
  payMethodIcon1,
  payMethodIcon2,
  payMethodIcon3,
  payMethodIcon4,
} from "../assets";

export const sliderImages = [
  {
    image: slideImg1,
  },
  {
    image: slideImg2,
  },

  {
    image: slideImg3,
  },
  {
    image: slideImg4,
  },
  {
    image: slideImg5,
  },
  {
    image: slideImg6,
  },
  {
    image: slideImg7,
  },
  {
    image: slideImg8,
  },
  {
    image: slideImg9,
  },
  {
    image: slideImg10,
  },
];

export const sliderTwoImages = [
  {
    image: slide2Img1,
  },
  {
    image: slide2Img2,
  },

  {
    image: slide2Img3,
  },
  {
    image: slide2Img4,
  },
  {
    image: slide2Img5,
  },
  {
    image: slide2Img6,
  },
  {
    image: slide2Img7,
  },
  {
    image: slide2Img8,
  },
  {
    image: slide2Img9,
  },
  {
    image: slide2Img10,
  },
];

export const gubaEnterpriseImages = [
  {
    image: gubaSlide2Img1,
  },
  {
    image: gubaSlide2Img2,
  },

  {
    image: gubaSlide2Img3,
  },
  {
    image: gubaSlide2Img4,
  },
  {
    image: gubaSlide2Img5,
  },
  {
    image: gubaSlide2Img6,
  },
  {
    image: gubaSlide2Img7,
  },
  {
    image: gubaSlide2Img8,
  },
  {
    image: gubaSlide2Img9,
  },
];

export const sponsors = [
  {
    image: spon1,
  },
  {
    image: spon2,
  },

  {
    image: spon3,
  },
  {
    image: spon4,
  },
  {
    image: spon5,
  },
  {
    image: spon6,
  },
  {
    image: spon7,
  },
  {
    image: spon8,
  },
  {
    image: spon9,
  },
];

export const testimonies = [
  {
    id: "0",
    text: " Being in Ghana made me appreciate my name more; made me more confident with who I am, Gayman says. “I understand why my father gave me this name, and I appreciate my West African heritage.”",
    person: " ",
  },
  {
    id: "1",
    text: " Wow!!! This is impressive. Brought tears to my eyes to see the amount of information you are providing. WOW!! I am speechless! Thank you very much. Becoming a member with GDN was the best decision ever! You give me great hope for the future of our people! THANK YOU ",
    person: "~ Natacha B (USA)",
  },
  {
    id: "2",
    text: "Jennifer, thank you so much for the information, l am blown away by your tenacity and professionalism. I am very grateful. I look forward to meeting you and the rest of the team in the near future.",
    person: "~ Diane Yaa Asantewaa, Vinneumont Events (UK)",
  },
  {
    id: "3",
    text: "Thank you so much Jennifer. I really appreciate y’all coming to see me off at the airport. That really made me smile inside. I also want to say thanks for all of your assistance during my stay and making my time here more effective. May God Bless You. Peace",
    person: "~ Corey Beckwith (USA)",
  },
  {
    id: "4",
    text: "Thank you so much for everything, you are amazing. We had the best time. I have been crying since we left the AirBnB. You have shown us so much kindness, we are overwhelmed! And we will be back",
    person: " ~ Jade (USA)",
  },
  {
    id: "5",
    text: " Thank you and the team for the continued support. And thank you for sharing this month's newsletter. Infact, what you and the team are doing is helping so many people like myself and I ask that you all continue the great work for mother Africa! I wanted to share a little bit of good news to you and the team. When I came to the meeting at the Ecobank head office last January, I mentioned I had a hotel project going on  in Konongo. From this meeting, I built a strong network and understanding from the different ministries who were present including the Tourism board. I just wanted to let you and the team know that I have successfully launched my hotel with all certifications in place. Of Course there were so many challenges getting things done to specifications but we made it! My wife and I have benefited a lot from this platform and the people we have met on this platform. For these reasons, Iwanted to share the good news with you all! Please see some pictures of our hotel... And let me know if there is any way we could be of help to anyone going through a similar process.Sincerely,",
    person: " ~ Francis Oduro (Canada)",
  },
];

const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const event = [
  {
    id: "1",
    image: oldSch,
    title: "An Evening of Old School Classics",
    details:
      "An evening of Old Skool Classic tunes from Ghana and around the world from the 60s, 70s, 80s, 90s and 00s from Hi-Life to RnB and more..... \n\n The GUBA Enterprise hosts a celebration evening of old skool classic tunes from Ghana and around the world at the luxurious Serenity Beach Resort overlooking the ocean whilst sipping on cocktails and enjoying the great vibes. Whether you wish to get up and dance to the music or just relax and chill with good company, the choice is yours; with a live band, interactive games, raffle, prizes and more on the night, it promises to be an evening to remember!!!",
    location: "Serenity Beach Resort Ghana",

    date: {
      day: "23",
      month: "december",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { src: oldSch1 },

      { src: oldSch3 },

      { src: oldSch5 },

      { src: oldSch7 },

      { src: oldSch9 },

      { src: oldSch11 },

      { src: oldSch13 },

      { src: oldSch15 },

      { src: oldSch17 },

      { src: oldSch19 },

      { src: oldSch21 },

      { src: oldSch23 },
    ],
  },
  {
    id: "2",
    image: dtc,
    title: "the Diaspora conversations conference",
    details:
      "The GUBA Diaspora Network (GDN) hosts this ‘must attend’ event for individuals and organisations seeking to connect, transition, do business and invest in Ghana. Attendees will be taken on a ‘Journey Through Ghana’ and engage with the various Governmental regulatory and private organisations they will likely need to reach out to in order to facilitate their needs.",
    location: "Ecobank Ghana (Head Office)",

    date: {
      day: "02",
      month: "january",
      year: "2023",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { src: con1 },
      { src: con2 },
      { src: con3 },
      { src: con4 },
      { src: con5 },
      { src: con6 },
      { src: con8 },
      { src: con9 },
      { src: con10 },
      { src: con11 },
      { src: con12 },
      { src: con13 },
      { src: con14 },
      { src: con15 },
      { src: con16 },
      { src: con17 },
      { src: con18 },
      { src: con19 },
      { src: con20 },
      { src: con21 },
      { src: con22 },
      { src: con23 },
      { src: con24 },
      { src: con25 },
      { src: con26 },
      { src: con27 },
      { src: con28 },
      { src: con29 },
      { src: con30 },
    ],
  },
  // {
  //   id: "3",
  //   image: gubaRetreat,
  //   title: "GUBA Retreat ",
  //   details:
  //     "A Give Back To Ghana, Renewal & Reflection Experience; for individuals & families to support Mother Africa with charitable deeds whilst exploring our history, culture  and purpose.",
  //   location: "Edmonton",
  //   date: {
  //     day: "26",
  //     month: "december",
  //     year: "2022",
  //   },

  //   youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
  //   yt_title:
  //     "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
  //   pictures: [{ src: gubaRetreat }],
  // },
  {
    id: "4",
    image: odo,
    title: "Odo – Love on the continent 2023",
    details:
      "Valentines event that brought diasporans together to discuss issues relating to love, marriage and relationship",
    location: "Club Smooth, Palace Mall Ghana",
    date: {
      day: "12",
      month: "february",
      year: "2023",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { src: odo1 },
      { src: odo2 },
      { src: odo3 },
      { src: odo4 },
      { src: odo5 },
      { src: odo6 },
      { src: odo7 },
      { src: odo8 },
      { src: odo9 },
      { src: odo10 },
      { src: odo11 },
      { src: odo12 },
      { src: odo13 },
      { src: odo14 },
      { src: odo15 },
      { src: odo16 },
      { src: odo17 },
      { src: odo18 },
      { src: odo19 },
      { src: odo20 },
      { src: odo21 },
      { src: odo22 },
      { src: odo23 },
      { src: odo24 },
      { src: odo25 },
      { src: odo26 },
      { src: odo27 },
      { src: odo28 },
      { src: odo29 },
    ],
  },
  {
    id: "5",
    image: meetUp,
    title: " GDN Meet Up 2023 ",
    details:
      "A networking event at the beach with the aim of bringing diasporans together to meet each other and catch up on everyone’s lives",
    location: "Bella Sun Beach Club",
    date: {
      day: "25",
      month: "june",
      year: "2023",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [{ src: meetUp }],
  },
];

export const events = shuffle(event);

export const upcomingEvents = [
  {
    id: "1",
    image: gubaAwards23,
    title: " GUBA Awards 2023",
    details:
      "An awards ceremony aimed at honoring Africans who are making exceptional impact in their fields. This year’s awards aims to honor beacons of excellence and inspiration. \n\n  GUBA AWARDS 2023 - Honoring Beacons of Excellence and Inspiration.  ",
    venue: "London, UK",
    date: "5th November 2023",
    time: "5pm - 11pm",
    ticketURL: "https://angelcommunities.org/guba-awards-2023",
  },
  {
    id: "2",
    image: afriExpo23,
    title: " AFRIEXPO 2023",
    details:
      "AfriExpo is a trade and business summit organized by GUBA Enterprise, Texas Africa Chamber of Commerce and Premanni Technologies. The event seeks to bring together corporations and small businesses, Industry experts and organizations in both the US and Africa to establish business partnerships, implement business ideas, and execute on business plans. The goal of the event is to move Africa beyond the conversations of what needs to be done and actually get it done in terms of businesses and economic growth. The maiden edition will be held in Houston, TX, the energy capital of the world. The top five industries representing at the event include: Technology, Health, Agriculture, Energy and Manufacturing. Africa Trade black business expo.",
    venue: "The ION, Houston, Texas ",
    date: "14th September 2023",
    time: "9am – 4pm",
    ticketURL: "https://www.eventbrite.com/e/670572820357",
  },
];

export const paymentContent = {
  supportText:
    "GDN  is committed to providing a seamless and secure payment process. The following payment processes and procedures have been developed to ensure that all payments are processed accurately and efficiently.",
  paymentGDN: [
    "Bank Account Transfer",
    "Zelle",
    "Paypal",
    "Payment methods, as agreed upon between GDN and the client.",
  ],
  methods: [
    {
      title: "Bank Account Transfer",
      description:
        "Members who wish to make payment via bank account transfer should follow these steps:",
      icon: payMethodIcon1,
      steps: [
        "Contact GDN via email or phone to request the necessary bank account details.",
        "Once the client receives the bank account details, they should initiate the transfer through their bank or online banking system.",
        "The client should email a copy of the transfer confirmation to GDN at info@gubadiasporanetwork.com",
        "Upon receipt of the transfer confirmation, GDN will confirm the receipt of payment and issue an invoice to the client.",
      ],
    },
    {
      title: "Zelle",
      description:
        "Members who wish to make payment via Zelle should follow these steps:",
      icon: payMethodIcon2,
      steps: [
        "Name: Akosua Amoateng.",
        " Email: dentaaguba@gmail.com",
        "Upon receipt, GDN will confirm the receipt of payment and issue an invoice to the client.",
      ],
    },
    {
      title: `Pay via PayPal`,
      description:
        "Persons who wish to make any payment through PayPal should click on the button below:",
      icon: payMethodIcon3,
      steps: [
        "In the message section, include your name, and any additional details.",
        "Upon receipt of the transfer, GDN will confirm the receipt of payment and issue an invoice to you.",
        "There is a 2.9% (£4.35) fee deducted for transactions when it reaches us. Please add the extra fee to ensure costs are covered fully.",
      ],
    },
    {
      title: "Pay with Credit / Debit Card",
      description:
        "Click the button below to choose your membership package",
      icon: payMethodIcon4,
      steps: [],
    },
  ],
};
