import { defineField, defineType } from 'sanity';

export default defineType({
    name: 'events',
    title: 'Events',
    type: 'document',
    fields: [
        defineField({
            name: 'title',
            title: 'Title',
            type: 'string'
        }),
        defineField({
            name: 'upcomingEventsText',
            title: 'Upcoming Events Text',
            type: 'string'
        }),
        defineField({
            name: "upcomingEvents",
            title: "Upcoming Events",
            type: "array",
            of: [{ type: "upcomingEvents" }],
        }),
        defineField({
            name: 'pastEventsText',
            title: 'Past Events Text',
            type: 'string'
        }),
        defineField({
            name: "pastEvents",
            title: "Past Events",
            type: "array",
            of: [{ type: "pastEvents" }],
        })
    ]
})