import React from "react";
import { Link } from "react-router-dom";
import { ScrollToTop, Slider, SliderReversed } from "../../components";
import { gubaEnterpriseImages } from "../../constants/data";
import gubaLogo from "../../assets/img/GUBA_white.png";

const GubaPage = () => {
  return (
    <>
      <section id="gubagoals">
        <div className="container">
          <div className="content">
            <div className="left">
              <img src={gubaLogo} alt="GUBA" />
            </div>
            <div className="middle">
              <div>
                <h1>GUBA ENTERPRISE</h1>
                <p>
                  GUBA Enterprise is a social non-profit organisation dedicated
                  to the advancement of diaspora Africans and Africans back home
                  through various socioeconomic programs and initiatives. The
                  organization currently has eight brand activities namely: GUBA
                  Awards, GUBA Foundation, GUBA Trade Expo, GUBA Careers, GUBA
                  Diaspora Card, Rock Your African Print GUBA Tours and the
                  newly instituted GUBA Diaspora Network.
                </p>
              </div>
              <h1>our goals</h1>
              <p>
                <b>Grow </b>
                –
                <br />
                by supporting businesses to grow, encouraging entrepreneurship,
                private sector development and initiatives.
                <br />
                <b> Unite </b>
                -
                <br />
                through the provision of an engaging network for promoting
                greater cultural diversity.
                <br />
                <b> Build </b>
                -
                <br />
                by providing business support and offering access to skills
                capital from business coaches to workshops,
                <br />
                <b> Africa </b>
                –
                <br />
                enhancing the continent through collective action, business and
                trade. GUBA Enterprise has become the Diaspora Network Manager
                par excellence and continues to grow into an impactful social
                venture network.
              </p>
            </div>
            <div className="right">
              <Link to="/" className="btn-primary-variant-gold">
                Visit the site
              </Link>
            </div>
          </div>
        </div>
        <SliderReversed sliders={gubaEnterpriseImages} />
        <ScrollToTop />
      </section>
    </>
  );
};

export default GubaPage;
