import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./coming-events.css";
import { upcomingEvents } from "../../constants/data";
import { Link } from "react-router-dom";
import { FaCalendar, FaMapMarker, FaClock } from "react-icons/fa";
import { UpcomingEvents, Modal, ScrollToTop } from "../../components";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";
import { formatLongDate } from "../../utils/formatDate";

const ComingEvents = () => {
  const [openModal, setOpenModal] = useState(false);
  const { upcomingEventId } = useParams();
  const [eventsData, setEventsData] = useState({});
  const [comingEvent, setComingEvent] = useState({});

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"]{..., pastEvents[] {..., image->}, upcomingEvents[] {..., image->} }`
      )
      .then((data) => {
        setEventsData(data[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("eventsData", eventsData);
    let upComingEvents = eventsData?.upcomingEvents;
    // set the current upcoming event
    let comingEvent = upComingEvents?.find(
      (event) => event?._key === upcomingEventId
    );
    setComingEvent(comingEvent);
  }, [eventsData]);

  useEffect(() => {
    console.log("comingEvent", comingEvent);
  }, [comingEvent]);

  return (
    <>
      <section
        className="upcomingDetailHeader"
        style={{ backgroundImage: `url(${urlFor(comingEvent?.image?.image)})` }}
      >
        <div className="blurBkg">
          <div className="container">
            <div className="eventHeader hor">
              <div className="left ver flex">
                <div className="eventTitle">
                  <h1>{comingEvent?.title}</h1>
                </div>
                <div className="eventInfo">
                  <div className="infoItemD">
                    <p>
                      <FaCalendar className="icon" />
                      {formatLongDate(comingEvent?.date)}
                    </p>
                  </div>
                  <div className="infoItemD">
                    <p>
                      <FaClock className="icon" />
                      {comingEvent?.time}
                    </p>
                  </div>
                  <div className="infoItemD">
                    <p>
                      <FaMapMarker className="icon" />
                      {comingEvent?.location}
                    </p>
                  </div>
                </div>
                <div className="buyTickets">
                  <Link
                    to={comingEvent?.ticketLink}
                    // onClick={() => setOpenModal(true)}
                    className="btn-primary-variant"
                    style={{ marginTop: "20px" }}
                    target="_blank"
                  >
                    buy ticket
                  </Link>
                </div>
              </div>
              <div className="right ver flex">
                <div className="imgWrapper">
                  <img
                    src={urlFor(comingEvent?.image?.image)}
                    alt={comingEvent?.title}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="eventDetail">
        <div className="container grayBackground">
          <div className="detailWrapper">
            <p>{comingEvent?.description}</p>
          </div>
        </div>
      </section>
      <section className="eventsSection" style={{ marginBottom: "4rem" }}>
        <div className="container">
          <UpcomingEvents
            title={eventsData?.upcomingEventsText}
            data={eventsData?.upcomingEvents}
          />
        </div>
      </section>
      {/* {openModal && <Modal closeModal={setOpenModal} />} */}
      <ScrollToTop />
    </>
  );
};

export default ComingEvents;
