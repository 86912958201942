import React from "react";
import "./initiatives.css";
import { ScrollToTop } from "../../components";

const Initiatives = () => {
  return (
    <>
      <section id="initiatives" className="initiatives">
        <div className="container">
          <article>
            <h1>initiatives</h1>
            <p>
              <b> Member Introductions</b>
              <br />
              <br />
              We encourage all members to introduce themselves to the network;
              share who you are, where you are based and what you do; also about
              your projects or business and what you hope to achieve by being
              part of The
              <b>GUBA</b>
              Diaspora Network.
            </p>
            <p>
              <b> 1-2-1 Meetings</b>
              <br />
              Every member is entitled to a free 20 minute meeting with a member
              of the team to understand their individual needs and what support
              is required.
              <br />
              Following the 1-2-1 meeting a detailed write up is sent with an
              overview of areas discussed, guidelines and information to support
              our members.
            </p>
            <p>
              <b> The Diaspora Conversations</b>
              <br />
              These open discussions are an opportunity to speak to the
              professionals and engage in conversations about topical areas
              affecting diasporans
            </p>
            <p>
              <b> Time To Give Back</b>
              <br />
              Charitable opportunities to support those in need and less
              fortunate than ourselves.
            </p>
            <p>
              <b> Newsletter</b>
              <br />
              Monthly overview of past events, what's happening within the
              network and across the GUBA Enterprise within the month and future
              plans.
            </p>
            <p>
              <b> Social Networking Meetups</b>
              <br />
              Regular informal networking sessions with members and those
              outside the network when invited.
            </p>
            <p>
              <b> Partnerships</b>
              <br />
              The network develops relationships with partner organizations to
              support diasporans through the various processes and their needs
              ie accommodation, co-working space, educational needs, cultural
              sessions, trips etc
            </p>
            <p>
              <b>E-Introductions</b>
              <br />
              We introduce members to partner organizations or to other members
              where requested and depending on their professional or social need
            </p>
            <p>
              <b>Business & Project Promotions</b>
              <br />
              Members are currently able to promote their businesses or project
              initiatives free of charge within the network;
              <br />
              We have a social media package for those who would like to promote
              beyond
              <br />
              The GUBA Diaspora Network community.
            </p>
            <p>
              <b> Special Day Celebrations & Awareness</b>
              <br />
              We encourage members to get involved in initiatives designed to
              raise awareness of causes or celebrate special days.
              <br /> <br />
              <b>Speaking Opportunities</b> <br />
              When the opportunity arises we discuss with and put forward
              members to speak at various events.
            </p>
            <p>
              <b> Investment Series</b>
              <br />
              An opportunity to find out and explore various investment
              initiatives
            </p>
            <p>
              <b> Internship Opportunities</b>
              <br />
              Through GUBA Careers, an opportunity to gain experience within
              various sectors in Ghana
            </p>
            <p>
              <b> Consultancy Services</b>
              <br />
              Additional services offered to members at an additional cost
            </p>
          </article>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Initiatives;
