import React, { useEffect, useState } from "react";
import "./event-card.css";
import { events } from "../../constants/data";
import { Link } from "react-router-dom";
import SKParagraph from "../Skeletons/SKParagraph";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";
import { splitDate } from "../../utils/formatDate";

const EventCard = ({ title, data }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventsData, setEventsData] = useState({});

  const eventsPerPage = 10;

  const endOffset = itemOffset + eventsPerPage;
  const currentEvents = events.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(events.length / eventsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * eventsPerPage) % events.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <section className="eventCard">
        <div className="header">
          <h3>{title || "Loading past events..."}</h3>
        </div>
        <div className="cardsWrapper">
          {
            // events &&
            // currentEvents
            //   .filter((event_name) =>
            //     event_name.title
            //       .toLowerCase()
            //       .includes(searchQuery.toLowerCase())
            //   )
            data?.length > 0 ? (
              data?.map((event) => (
                <Link to={`/events/past-events/${event._key}`} key={event._key}>
                  <div className="card">
                    <div className="date">
                      <div className="day">{splitDate(event.date).day}</div>
                      <small className="month">
                        {splitDate(event.date).month}
                      </small>
                      <div className="year">{splitDate(event.date).year}</div>
                    </div>
                    <div className="info">
                      <div className="image">
                        <img
                          src={urlFor(event.image.image)}
                          alt={event.title}
                          loading="lazy"
                        />
                      </div>
                      <div className="details">
                        <div className="title">
                          <h2>{event.title}</h2>
                        </div>
                        <div className="location">
                          <p>{event.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <Skeleton height={300} />
                  </div>
                  <div className="col-lg-8">
                    <SKParagraph />
                    <br />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </section>
    </>
  );
};

export default EventCard;
