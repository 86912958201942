import { defineType } from "sanity";
import { TbVideo } from "react-icons/tb";

export default defineType({
    name: "videoGallery",
    title: "Video Gallery",
    type: "document",
    icon: TbVideo,
    fields: [
        {
            name: "video",
            title: "Video",
            type: "file",
            description: "The video file",
            fields: [
                {
                    name: "alt",
                    title: "Alternative text",
                    type: "string",
                }
            ]
        },
        {
            name: "display",
            title: "Display as",
            type: "string",
            description: "How should we display the video?",
            options: {
                list: [
                    { title: "Stacked on top of each other", value: "stacked" },
                    { title: "In-line", value: "inline" },
                    { title: "Carousel (recommended)", value: "carousel" } // recommended
                ],
                layout: "radio"
            }
        },
        {
            name: "zoom",
            title: "Zoom enabled",
            type: "boolean"
        }
    ],
    preview: {
        select: {
            video: "video"
        },
        prepare(selection) {
            const { video } = selection;
            return {
                title: `${video.alt}`,
                media: video
            };
        }
    }
});