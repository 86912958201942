import React, { useState } from "react";
import "../../global.css";
import "./payment.css";
import { bankCards, donateSticker, Logo } from "../../assets";
import { paymentContent } from "../../constants/data";
import { ScrollToTop, BankInfoModal } from "../../components";
import { Link } from "react-router-dom";

const Payments = () => {
  const [openBankInfoModal, setOpenBankInfoModal] = useState(false);

  const showBankInfoModal = () => {
    setOpenBankInfoModal(true);
  };

  const closeBankInfoModal = () => {
    setOpenBankInfoModal(false);
  };

  return (
    <>
      {/* Support a cause */}
      <section className="supportSection pt-8">
        <div className="container pt-4">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <div className="d-md-flex align-items-end pb-3 d-none">
                <h1 className="supportText">Make</h1>&nbsp;&nbsp;&nbsp;
                <img
                  loading="lazy"
                  src={Logo}
                  className="img-fluid supportImg"
                />
                <h1 className="supportText">Payments</h1>
              </div>
              <div className="d-block d-md-none">
                <h1 className="supportText text-center">Make payments</h1>
              </div>
              <div className="px-lg-5 supportMargin">
                <p>{paymentContent.supportText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* payment methods */}
      <section className="paymentMethodList">
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div className="px-lg-5 supportMargin">
                <h2 style={{ marginBottom: "25px", marginTop: "20px" }}>
                  GDN accepts the following
                </h2>

                <ul className="methodList">
                  {paymentContent.paymentGDN.map((method, index) => (
                    <li key={"payM#" + index}>
                      <span className="ml-3 ml-lg-0">{method}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">{/* <DonatePayment /> */}</div>
        </div>
      </section>

      {/* Payment process */}
      <section className="paymentProcess my-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center justify-content-center">
              <h2 className="mb-4">Make Payments</h2>
            </div>
          </div>

          {/* methods */}
          <div className="row mt-5 pt-lg-3 pt-5 card-group">
            {/* bank card */}
            <div className="col-lg-6 col-12">
              <div className="card h-100 bank position-relative">
                <img
                  loading="lazy"
                  src={paymentContent.methods[0].icon}
                  className="donateIcon"
                />

                {/* header */}
                <div className="card-header text-center">
                  <h2 className="card-title pt-5">
                    {paymentContent.methods[0].title}
                  </h2>
                  <p className="card-text px-lg-3">
                    {paymentContent.methods[0].description}
                  </p>
                </div>

                {/* body */}
                <div className="card-body pb-2">
                  <ul>
                    {paymentContent.methods[0].steps.map((step, index) => (
                      <li key={"step#" + index}>
                        <span className="ml-3">{step}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex justify-content-center">
                    <button onClick={showBankInfoModal} className="btn">
                      Request Bank Details
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* cheque card */}
            <div className="col-lg-6 col-12">
              <div className="card h-100 bank position-relative">
                <img
                  loading="lazy"
                  src={paymentContent.methods[1].icon}
                  className="donateIcon"
                />

                {/* header */}
                <div className="card-header text-center">
                  <h2 className="card-title pt-5 cheque">
                    {paymentContent.methods[1].title}
                  </h2>
                  <p className="card-text px-lg-3">
                    {paymentContent.methods[1].description}
                  </p>
                </div>

                {/* body */}
                <div className="card-body pb-2">
                  <ul>
                    {paymentContent.methods[1].steps.map((step, index) => (
                      <li key={"step#" + index}>
                        <span className="ml-3">{step}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* email money card */}
            <div className="col-lg-6 col-12">
              <div className="card h-100 bank position-relative">
                <img
                  loading="lazy"
                  src={paymentContent.methods[2].icon}
                  className="donateIcon"
                />

                {/* header */}
                <div className="card-header text-center">
                  <h2 className="card-title pt-5 emailMoney">
                    {paymentContent.methods[2].title}{" "}
                    {/* <span>info@hohcentre.co.uk</span> */}
                  </h2>
                  <p className="card-text px-lg-3">
                    {paymentContent.methods[2].description}
                  </p>
                </div>

                <div className="d-flex justify-content-center mt-2">
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.paypal.me/diasporacard"
                  >
                    Subscribe Now
                  </a>
                </div>

                {/* body */}
                <div className="card-body pb-2">
                  <ul>
                    {paymentContent.methods[2].steps.map((step, index) => (
                      <li key={"step#" + index}>
                        <span className="ml-3">{step}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* other payment methods */}
            <div className="col-lg-6 col-12">
              <div className="card h-100 bank position-relative">
                <img
                  loading="lazy"
                  src={paymentContent.methods[3].icon}
                  className="donateIcon"
                />

                {/* header */}
                <div className="card-header text-center">
                  <h2 className="card-title pt-5 other">
                    {paymentContent.methods[3].title}
                  </h2>
                  <p className="card-text px-lg-2">
                    {paymentContent.methods[3].description}
                  </p>
                </div>

                <div className="d-flex justify-content-center mt-2">
                  {/* <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.paypal.me/diasporacard "
                  >
                    Subscribe Now
                  </a> */}
                  <Link to="/pricing" className="btn">
                    Subscribe Now
                  </Link>
                </div>

                {/* body */}
                <div className="card-body pb-2">
                  <ul>
                    {paymentContent.methods[3].steps.map((step, index) => (
                      <li key={"step#" + index}>
                        <span className="ml-3">{step}</span>
                      </li>
                    ))}
                  </ul>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={bankCards}
                      className="img-fluid bank-cards"
                      style={{ width: "20rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* sticker */}
      <section className="stickerSection">
        <div className="container py-5 mb-4">
          <div className="row w-75">
            <div className="col-12">
              <img
                loading="lazy"
                src={donateSticker}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {openBankInfoModal && <BankInfoModal closeModal={setOpenBankInfoModal} />}
      <ScrollToTop />
    </>
  );
};

export default Payments;
