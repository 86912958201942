import { defineConfig } from 'sanity'
import { structureTool } from 'sanity/structure'
import { visionTool } from '@sanity/vision'
import { deskStructure } from './deskStructure'
import { deskTool } from 'sanity/desk'
import { schemaTypes } from './schemaTypes'
import { GDNLogo } from './components'

export default defineConfig({
  name: 'default',
  title: 'GDN Dashboard',
  icon: GDNLogo,

  projectId: process.env.REACT_APP_PROJECT_ID,
  dataset: 'production',

  basePath: "/admin",

  plugins: [
    structureTool({
      structure: deskStructure
    }),
    // visionTool()
  ],

  schema: {
    types: schemaTypes,
  },
})
