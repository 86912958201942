import React from "react";
import Skeleton from "react-loading-skeleton";

const SkCardText = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <Skeleton height={20} style={{ width: "100%" }} />
      <Skeleton height={20} style={{ width: "80%" }} />
      <Skeleton height={20} style={{ width: "50%" }} />
    </div>
  );
};

export default SkCardText;
