import { AiOutlineHome } from "react-icons/ai"
import { MdArticle, MdMiscellaneousServices, MdOutlineEventNote } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";

export const deskStructure = (S) =>
    S.list()
        .title('Content')
        .items([

            S.listItem()
                .title('Home')
                .icon(AiOutlineHome)
                .child(
                    S.editor()
                        .schemaType('home')
                        .documentId('homePage')
                        .title('Home Page')
                ),

            S.listItem()
                .title('About Us')
                .icon(FaInfoCircle)
                .child(
                    S.editor()
                        .schemaType('about')
                        .documentId('aboutPage')
                        .title('About Page')
                ),

            S.listItem()
                .title('Services')
                .icon(MdMiscellaneousServices)
                .child(
                    S.editor()
                        .schemaType('services')
                        .documentId('servicesPage')
                        .title('Services Page')
                ),

            S.listItem()
                .title('Events')
                .icon(MdOutlineEventNote)
                .child(
                    S.editor()
                        .schemaType('events')
                        .documentId('eventsPage')
                        .title('Events Page')
                ),

            // // Add a visual divider (optional)
            S.divider(),

            // List out the rest of the document types, but filter out the config type
            ...S.documentTypeListItems()
                .filter(listItem => !['home', 'about', 'services', 'events'].includes(listItem.getId()))
        ])