export const navigationLinks = [
  {
    id: "1",
    name: "About Us",
    url: "/aboutus",
  },

  {
    id: "2",
    name: "Services",
    url: "/services",
  },
  {
    id: "3",
    name: "Initiatives",
    url: "/initiatives",
  },

  {
    id: "4",
    name: "Events",
    url: "/events",
  },
  // {
  //   id: "5",
  //   name: "Directory",
  //   url: "/directory",
  // },
  // {
  //   id: "6",
  //   name: "Donate",
  //   url: "/donate",
  // },
  // {
  //   id: "7",
  //   name: "Join",
  //   url: "/joinus",
  // },
  // {
  //   id: "5",
  //   name: "login",
  //   url: "/login",
  // },
];

export const ourServiceLinks = [
  {
    id: "1",
    name: "mineral fraud recoveries",
    url: "/mineral_fraud",
  },
  {
    id: "2",
    name: "mineral purchases",
    url: "/mineral_purchases",
  },
  {
    id: "3",
    name: "data protection services",
    url: "/data_protection",
  },
];

export const footerMenuLinks = {
  supportLinks: {
    title: "support",
    links: [
      {
        id: "1",
        name: "privacy policy",
        url: "/privacy-policy",
      },
      {
        id: "2",
        name: "cookie policy",
        url: "/cookie-policy",
      },
      {
        id: "3",
        name: "terms and conditions",
        url: "/terms-and-conditions",
      },
    ],
  },
  menuLinks: {
    title: "menu",
    links: [
      {
        id: "1",
        name: "GDN Trends",
        url: "/gdn-trends",
      },
      {
        id: "2",
        name: "about us",
        url: "/aboutus",
      },
      {
        id: "3",
        name: "services",
        url: "/services",
      },
      {
        id: "4",
        name: "initiatives",
        url: "/initiatives",
      },
      {
        id: "5",
        name: "member login",
        url: "/login",
      },
      {
        id: "6",
        name: "events",
        url: "/events",
      },
      {
        id: "7",
        name: "business",
        url: "/business",
      },
      {
        id: "8",
        name: "jobs",
        url: "/jobs",
      },
    ],
  },
};
