import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import Layout from '../Layouts/Layout';
import AdminLayout from "../Layouts/AdminLayout";
import { AboutUs, AdminPage, ComingEvents, CookiePolicy, EventDetail, Events, GubaPage, Home, Initiatives, JoinUs, NotFoundPage, Payments, Pricing, PrivacyPolicy, Services, TermsAndConditions } from '../pages';
import EventDetailLayout from '../Layouts/EventDetailLayout';

const AdminWrapper = () => {
    return (
        <>
            <AdminLayout>
                <Outlet />
            </AdminLayout>
        </>
    );
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <Home /> },
            { path: 'initiatives', element: <Initiatives /> },
            { path: 'joinus', element: <JoinUs /> },
            { path: 'services', element: <Services /> },
            { path: 'aboutus', element: <AboutUs /> },
            { path: 'events', element: <Events /> },
            {
                path: 'events',
                element: <EventDetailLayout />,
                children: [
                    { path: 'past-events/:eventId', element: <EventDetail /> },
                    { path: 'upcoming-events/:upcomingEventId', element: <ComingEvents /> }
                ]
            },
            { path: 'payments', element: <Payments /> },
            { path: 'guba', element: <GubaPage /> },
            { path: '*', element: <NotFoundPage /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'cookie-policy', element: <CookiePolicy /> },
            { path: 'terms-and-conditions', element: <TermsAndConditions /> },
            { path: 'pricing', element: <Pricing /> }
        ]
    },
    {
        path: "/admin",
        element: <AdminWrapper />, // This uses the Admin layout
        children: [
            { index: true, element: <AdminPage /> }, // Admin home page
            { path: "*", element: <Navigate to="/admin" replace /> }, // Redirect any other paths to /admin
        ],
    },
]);

export default router;