import { defineType } from "sanity";

export default defineType({
    name: "pastEvents",
    title: "Past Events",
    type: "object",
    fields: [
        {
            name: "title",
            title: "Title",
            type: "string",
        },
        {
            name: "image",
            title: "Image",
            type: "reference",
            to: [{ type: "gallery" }],
        },
        {
            name: "description",
            title: "Description",
            description: "A detailed description of the event.",
            type: "text",
        },
        {
            name: "date",
            title: "Date",
            type: "datetime",
        },
        {
            name: "location",
            title: "Location",
            type: "string",
        },
        {
            name: 'eventGallery',
            title: 'Event Gallery',
            type: 'array',
            of: [
                {
                    type: 'reference',
                    to: [{ type: 'gallery' }],
                },
            ],
        }
    ],
    preview: {
        select: {
            title: "title",
            media: "image.image",
        },
    },
});