import React, { useEffect, useState } from "react";
// import "./home.css";
import { Logo, gubaEnt, logoWhite } from "../../assets";
import {
  HeaderSwiper,
  MissionCards,
  SKParagraph,
  ScrollToTop,
  Slider,
  SliderReversed,
  TestimonySlider,
  VideoSwiper,
} from "../../components";
import { sliderImages, sliderTwoImages, sponsors } from "../../constants/data";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import "./home.css";
import { urlFor } from "../../utils/urlFor";

const Home = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"]{..., missionCards[] {..., icon->}}`)
      .then((data) => {
        setHomeData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("homeData", homeData);
  }, [homeData]);

  return (
    <>
      <HeaderSwiper />
      <section id="welcome">
        <div className="hands__mark">
          <div className="container">
            <div className="top">
              <div className="left">
                <h1>
                  {homeData?.welcomeTitle || (
                    <Skeleton height={65} width={300} />
                  )}
                </h1>
                <p>{homeData?.welcomeDescription || <SKParagraph />}</p>
              </div>
              <div className="right"></div>
            </div>
            <div className="bottom">
              <div className="left"></div>
              <div className="right">
                <img src={Logo} alt="GDN Logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about">
        <div class="container">
          <div class="about__content">
            <h1>
              {homeData?.aboutTitle || <Skeleton height={65} width={300} />}
            </h1>
            <p>{homeData?.aboutDescription || <SKParagraph />}</p>
            <Link to="/aboutus" class="btn-primary-variant">
              Learn more
            </Link>
          </div>
          <MissionCards missionCards={homeData?.missionCards} />
        </div>
        <Slider sliders={sliderImages} />
      </section>
      <section id="community">
        <div className="container">
          <div className="top">
            <div className="left">
              <h1>
                {homeData?.communityTitle || (
                  <Skeleton height={65} width={300} />
                )}
              </h1>
              <p>{homeData?.communityDescription || <SKParagraph />}</p>
              <Link to="/" className="btn2">
                Learn more
              </Link>
            </div>
            <div className="right"></div>
          </div>
          <div className="bottom">
            <div className="left"></div>
            <div className="right">
              <div className="btn-hoder">
                <Link to="/joinus" className="btn-primary-variant">
                  Join us
                </Link>
              </div>
              <div className="right-text">
                <p>
                  Interactive section for members to engage with each other. Not
                  GDN member yet? Register to get your GDN key to sign up on the
                  community page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials">
        <div class="container">
          <div class="testi__header">
            <h1>
              {homeData?.testimonialsTitle || (
                <Skeleton height={65} width={300} />
              )}
            </h1>
          </div>
          <TestimonySlider testimonials={homeData?.testimonyMessages} />
        </div>
        <VideoSwiper />
        {/* <div class="testi__videos swiper videoSwiper">
        <div class="swiper-wrapper testi-vid-wrapper">
            <div class="testi__video swiper-slide">
                <img src="assets/img/Mask Group 1.png" alt="">
                <div class="play__btn">
                    <img src="assets/img/play-button_white.png" alt="">
                </div>
            </div>
            <div class="testi__video swiper-slide">
                <img src="assets/img/Mask Group 2.png" alt="">
                <div class="play__btn">
                    <img src="assets/img/play-button_white.png" alt="">
                </div>
            </div>
            <div class="testi__video swiper-slide">
                <img src="assets/img/Mask Group 3.png" alt="">
                <div class="play__btn">
                    <img src="assets/img/play-button_white.png" alt="">
                </div>
            </div>
            <div class="testi__video swiper-slide">
                <img src="assets/img/Mask Group 2.png" alt="">
                <div class="play__btn">
                    <img src="assets/img/play-button_white.png" alt="">
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div> */}
      </section>
      <section id="guba">
        <div className="container">
          <div className="left">
            <img src={gubaEnt} alt="GUBA Enterprise" />
            <p>{homeData?.enterpriseDescription || <SKParagraph />}</p>
            <Link to="/guba" class="btn-primary-variant-gold">
              Learn more
            </Link>
          </div>
          <div className="right">
            <img src={logoWhite} alt="GUBA" />
          </div>
        </div>
        <SliderReversed sliders={sliderTwoImages} />
      </section>
      <section id="sponsors">
        <div className="container">
          <div className="partners-title">
            <h1 className="">
              {homeData?.ourPartnersTitle || (
                <Skeleton height={65} width={300} />
              )}
            </h1>
          </div>
          <p>{homeData?.ourPartnersDescription || <SKParagraph />}</p>
          <div className="sponsor-logos">
            {homeData?.partners?.map((sponsor, index) => (
              <div className="sponsor-logo" key={index}>
                <img src={urlFor(sponsor)} alt="" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Home;
