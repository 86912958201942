import React, { useState } from "react";
import "../../global.css";
import { FaCheck } from "react-icons/fa";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { countryOptions } from "./countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./pricing.css";
import { additionalServices } from "./additionalServices";
import { ScrollToTop, Spinner } from "../../components";
import { SERVER_URL } from "../../utils/constant";
import { Link } from "react-router-dom";

const PricingBox = ({
  title,
  price,
  type = "general",
  features = [],
  btnText = "Subscribe Now",
  btnLink,
  action = () => {},
}) => {
  const [showMore, setShowMore] = useState(false);
  const maxFeaturesToShow = 2; // Number of features to show initially

  const toggleShowMore = () => setShowMore(!showMore);

  const displayedFeatures = showMore
    ? features
    : features.slice(0, maxFeaturesToShow);

  return (
    <div className="card mb-5 mb-lg-0">
      <div className="card-body">
        <h5 className="card-title text-muted text-uppercase text-center">
          {title}
        </h5>
        <h6 className="card-price text-center">
          {type != "additional" ? (
            <>
              ${price}
              <span className="period">/year</span>
            </>
          ) : (
            "Let's Talk"
          )}
        </h6>
        <hr />
        {title.includes("Consultation") && (
          <p className="mb-0 mt-0">
            This is a one-time service offered to Individuals who require our
            help without necessarily joining our network. <br />
            Pricing for this package is dependent on the depth of help thot you
            require from us.
          </p>
        )}

        {title.includes("Additional") && (
          <p className="mb-0 mt-0">
            Our additional services include:{" "}
            <i>
              Introductions or meetings arranged with private and public sector
              offices, Work experience & Internship through GUBA Careers
            </i>{" "}
            and more.
          </p>
        )}

        {features?.length > 1 ? (
          <ul className="fa-ul">
            {displayedFeatures.map((feature, index) => (
              <li key={index}>
                <FaCheck size={15} className="mr-2" />
                {index == 0 ? <strong>{feature}</strong> : feature}
              </li>
            ))}
            {features.length > maxFeaturesToShow && (
              <li className="show-more-link">
                <a href="#" onClick={toggleShowMore}>
                  {showMore
                    ? "Show Less"
                    : `Show More (${
                        features.length - maxFeaturesToShow
                      } items)`}
                </a>
              </li>
            )}
          </ul>
        ) : features?.length === 1 ? (
          <p className="mx-0 mb-3">{features[0]}</p>
        ) : (
          <p className="mx-0 mb-3">Click to find out</p>
        )}

        {btnLink ? (
          <>
            <a
              href={btnLink}
              target="_blank"
              className="btn btn-block btn-primary text-uppercase"
            >
              {btnText}
            </a>
            <Link to="/payments" className="btn-link">
              Other Payment Methods
            </Link>
          </>
        ) : (
          <a
            className="btn btn-block btn-primary text-uppercase"
            onClick={() => action()}
          >
            {btnText}
          </a>
        )}
      </div>
    </div>
  );
};

const Pricing = () => {
  const [open, setOpen] = useState(false);
  const [nextOpen, setNextOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");

  const [userService, setUserService] = useState({});
  const [loading, setLoading] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const onOpenNextModal = () => setNextOpen(true);
  const onCloseNextModal = () => setNextOpen(false);

  const submitForm = (e) => {
    e.preventDefault();
    console.log("submitting form");
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log("form data: ", data);
    setUserService({ ...userService, ...data });
    let userService_ = { ...userService, ...data };
    console.log("user service: ", userService_);

    if (!userService_.service) {
      alert("Please select a service");
      return;
    }

    setLoading(true);

    // send data to backend
    fetch(`${SERVER_URL}/submit-additional-service`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...userService_ }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data: ", data);
        setLoading(false);

        if (data.success) {
          // reset form
          form.reset();
          alert(
            "Your request has been submitted successfully. We will get back to you soon."
          );
          onCloseNextModal();
        } else {
          setLoading(false);
          console.log("error: ", data.error);
          alert("An error occurred. Please try again later");
          onCloseNextModal();
        }
      })
      .catch((err) => {
        console.log("error: ", err);
        setLoading(false);
        alert("An error occurred. Please try again later");
        onCloseNextModal();
      });
  };

  const nextForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    data.phone = phone;
    console.log("form data: ", data);

    if (!data.fullName || !data.email || !data.country || !data.phone) {
      alert("Please fill out all fields");
      return;
    }

    setUserService(data);

    setNextOpen(true);
    setOpen(false);
    // submitForm(e);
  };

  const consultationForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    data.phone = phone;
    console.log("form data: ", data);

    if (!data.fullName || !data.email || !data.country || !data.phone2) {
      alert("Please fill out all fields");
      return;
    }

    setLoading(true);

    // setUserService(data);
    fetch(`${SERVER_URL}/submit-consultation-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data: ", data);

        setLoading(false);
        if (data.success) {
          // reset form
          form.reset();
          alert(
            "Your request has been submitted successfully. We will get back to you soon."
          );
          onCloseModal2();
        } else {
          console.log("error: ", data.error);
          alert("An error occurred. Please try again later");
          onCloseModal2();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error: ", err);
        alert("An error occurred. Please try again later");
        onCloseModal2();
      });

    // console.log('contact: ', );
  };

  return (
    <>
      <section id="pricing" className="pricing">
        <div className="container">
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h1>MEMBERSHIP PACKAGES</h1>
            <p className="mb-4 mt-3">
              In order to better serve our community, we have created a range of
              membership packages to suit your needs. This includes{" "}
              <strong>individual </strong>
              and <strong>corporate</strong> memberships. Please see below for
              more information.
            </p>
          </div>

          {/* individual membership */}
          <div className="row indiv justify-content-center">
            <div className="mb-3">
              <h2>Individual Membership</h2>
              <p className="text-center mb-3">
                For individuals living in Ghana or the diaspora seeking
                assistance or wishing to network.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 mb-3 order-2 order-lg-1 order-md-1">
              <PricingBox
                title="General Package"
                price="300"
                btnLink={"https://flutterwave.com/pay/ikgzcqizrmod"}
                features={[
                  "Access to the GUBA Diaspora Network Community",
                  "Opportunity to join our private Facebook Group with community discussions, interactions and more",
                  "Two (2) free events as well as subsidized prices for further GDN events throughout the year",
                  "Added to our GDN WhotsApp and Google Group of professionals",
                  "GDN Seat virtual event to promote your business, projects or shore about yourself to the diosporon community",
                  "Networking sessions with like-minded individuals",
                  "Engagement with Government institutions and private organisations",
                  "Conferences, International Projects, Online Sessions, Give Back Initiatives and more",
                ]}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-3 premium order-1 order-lg-2 order-md-2">
              <PricingBox
                title="Premium Package"
                price="650"
                btnLink={"https://flutterwave.com/pay/9tiuhc8egfwv"}
                features={[
                  "General Membership Benefits",
                  "A 1-2-1 consultation with our team to understand your requirements",
                  "A detailed write up with information and recommendations to meet your needs",
                  "A total of five (5) free events and discounted events for the rest of the year.",
                ]}
              />
            </div>

            {/* <div className="col-lg-4 col-md-6 mb-3">
              <PricingBox
                title="Consultation Package"
                price="650"
                features={[
                  "This is a one-time service offered to Individuals who require our help without necessarily joining our network. Pricing for this package is dependent on the depth of help thot you require from us.",
                ]}
              />
            </div> */}

            <div className="col-lg-4 col-md-6 mb-3 order-3">
              <PricingBox
                title="Additional Service"
                price="350"
                type="additional"
                features={["Click here to find out more."]}
                btnText="Contact Us"
                action={onOpenModal}
              />
            </div>
          </div>

          {/* corporate membership */}
          <div className="row corporate mt-3 justify-content-center ">
            <div className="mb-3">
              <h2>Corporate Membership</h2>
              <p className="text-center mb-3">
                For organisations in Ghana or abroad seeking engagement with the
                diosporon community or general support.
              </p>
            </div>

            <div className="col-lg-4 col-12">
              <PricingBox
                title="Consultation Package"
                price=""
                type="additional"
                features={["Click here to ask about our consultation services"]}
                btnText="Contact Us"
                action={onOpenModal2}
              />
            </div>
            <div className="col-lg-4 col-12 premium">
              <PricingBox
                title="Corporate Membership"
                price="3000"
                btnLink={"https://flutterwave.com/pay/chkuq7n7k9wa"}
                features={[
                  "Annual corporate membership to the GUBA Diaspora Network",
                  "A one (1) hour Consultation with our team to understand your organisations requirements",
                  "A planning schedule to meet your organization's needs",
                  `Two (2) Virtual Diaspora Conversations about your organization`,
                  `Two (2) 45 minute review meeting with your organisation throughout the year`,
                  `An agreed promotional package of products and services via our social media platforms`,
                  `One (1) collaborative event with the GDN community`,
                  `Placement on our GDN website`,
                  `An agreed partnership with GDN`,
                  `Membership incentives`,
                ]}
              />
            </div>
            <div className="col-lg-4 col-12"></div>
          </div>
        </div>
      </section>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <h2 className="mb-2">Contact Us</h2>
        <p className="mb-2 form-desc">
          Please fill out the form below and we will get back to you as soon as
          possible.
        </p>
        <form onSubmit={nextForm}>
          <div className="form-group">
            <label htmlFor="name">Full name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="fullName"
              placeholder="Enter your name"
            />
          </div>
          {/* countries */}
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select className="form-control" id="country" name="country">
              <option value="">Country of residence</option>
              {countryOptions.map((country, index) => (
                <option key={index} value={country.value}>
                  {country.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          {/* phone number */}
          <div className="form-group">
            <label htmlFor="phone">Phone number</label>
            <PhoneInput
              placeholder="Enter phone number"
              country={"gh"}
              value={phone}
              className="form-control phone"
              onChange={setPhone}
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
              placeholder="Enter your message"
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Next
          </button>
        </form>
      </Modal>

      {/* next form modal */}
      <Modal
        open={nextOpen}
        onClose={onCloseNextModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <h2 className="mb-2">Contact Us</h2>
        <p className="mb-2 form-desc">
          Please select the additional service you are interested in.
        </p>
        <form onSubmit={submitForm}>
          {/* select additional services */}
          <div className="form-group">
            <label htmlFor="service">
              Select one of our additional services
            </label>
            <select className="form-control" id="service" name="service">
              <option value="">Select service</option>
              {additionalServices.map((service, index) => (
                <option key={index} value={service.value}>
                  {service.label}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {loading ? <Spinner /> : "Submit"}
          </button>
        </form>
      </Modal>

      {/* consultation modal form */}
      <Modal
        open={open2}
        onClose={onCloseModal2}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <h2 className="mb-2">Contact Us</h2>
        <p className="mb-2 form-desc">
          Please fill out the form below and we will get back to you as soon as
          possible.
        </p>

        <form onSubmit={consultationForm}>
          <div className="form-group">
            <label htmlFor="name">Full name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="fullName"
              placeholder="Enter your name"
            />
          </div>
          {/* countries */}
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select className="form-control" id="country" name="country">
              <option value="">Country of residence</option>
              {countryOptions.map((country, index) => (
                <option key={index} value={country.value}>
                  {country.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          {/* phone number */}
          <div className="form-group">
            <label htmlFor="phone">Phone number</label>
            <PhoneInput
              placeholder="Enter phone number"
              country={"gh"}
              value={phone2}
              className="form-control phone"
              onChange={setPhone2}
              inputProps={{
                name: "phone2",
              }}
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
              placeholder="Enter your message"
            ></textarea>
          </div>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {loading ? <Spinner /> : "Submit"}
          </button>
        </form>
      </Modal>

      <ScrollToTop />
    </>
  );
};

export default Pricing;
