import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import {
  FaEnvelope,
  FaPhone,
  FaYoutube,
  FaFacebookF,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
import { footerMenuLinks } from "./../../constants/links";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <>
      <footer>
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="companyDetail">
                <div className="logo">
                  {/* <img loading="lazy" src={} alt="logo" /> */}
                </div>
                <div className="description">
                  <p>
                    Seeking to connect, transition or do business and invest in
                    Ghana? Contact us to find out how we can help!!
                  </p>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="menuItem">
                <div className="header">
                  <h3>{footerMenuLinks.supportLinks.title}</h3>
                </div>
                <div className="menuList">
                  {footerMenuLinks.supportLinks.links.map((links) => {
                    return (
                      <Link
                        className="service_menu_item"
                        key={links.id}
                        to={links.url}
                      >
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="menuItem">
                <div className="header">
                  <h3>{footerMenuLinks.menuLinks.title}</h3>
                </div>
                <div className="menuList">
                  {footerMenuLinks.menuLinks.links.map((links) => {
                    return (
                      <Link
                        className="service_menu_item"
                        key={links.id}
                        to={links.url}
                      >
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="menuItem">
                <div className="header">
                  <h3>contact us</h3>
                </div>
                <div className="address">
                  <article>
                    <p>
                      15th Close, Alhaji Sulley Street, <br />
                      Abelemkpe <br />
                      Accra, Ghana
                    </p>
                    <div className="contacts">
                      <div className="contactItem">
                        <div className="icon">
                          <FaPhone />
                        </div>
                        <a href="tel:+233245156705">+233 (0) 24 515 6705</a>
                      </div>
                      <div className="contactItem">
                        <div className="icon">
                          <FaEnvelope />
                        </div>
                        <a href="mailto:info@GUBADiasporaNetwork.com">
                          info@GUBADiasporaNetwork.com
                        </a>
                      </div>
                    </div>
                    <div className="socials">
                      <Link
                        className="icons"
                        target="_blank"
                        to={
                          "https://twitter.com/GUBADiasporaNwk?t=CA0lsc1NwhOerSJ9ojw8bQ&s=09"
                        }
                      >
                        <FaTwitter className="icon" />
                      </Link>
                      <Link
                        className="icons"
                        target="_blank"
                        to={
                          "https://instagram.com/gubadiasporanetwork?igshid=ZDc4ODBmNjlmNQ=="
                        }
                      >
                        <FaInstagramSquare className="icon" />
                      </Link>
                      <Link
                        className="icons"
                        target="_blank"
                        to={
                          "https://www.facebook.com/groups/475703410944780/?ref=share&mibextid=SDPelY"
                        }
                      >
                        <FaFacebookF className="icon" />
                      </Link>

                      <Link
                        className="icons"
                        target="_blank"
                        to={"https://m.youtube.com/c/ODANANETWORK"}
                      >
                        <FaYoutube className="icon" />
                      </Link>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="copy-name">
              &copy; {year} <Link to={"./"}> GDN</Link> | Designed & developed
              by |
              <a
                href="http://www.veltetech.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {""} Velte Technology Solutions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
