import React from "react";

import { testimonies } from "./../../constants/data";
import "./slider.css";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SKParagraph from "../Skeletons/SKParagraph";

// import "swiper/css";
// import "swiper/css/pagination";

const TestimonySlider = ({ testimonials }) => {
  return testimonials?.length > 0 ? (
    <Swiper
      loop={false}
      navigation={true}
      grabCursor={true}
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="testimonySwiper"
    >
      {testimonials?.map((links) => {
        return (
          <SwiperSlide key={links._key} className="testimonial">
            <article className="swiper-slide">
              <p>{links.message}</p>
              <div className="testifier ">
                <div className="testifier __details">
                  <h5>{links.author}</h5>
                </div>
              </div>
            </article>
          </SwiperSlide>
        );
      })}
    </Swiper>
  ) : (
    <div
      style={{
        marginBottom: "3rem",
      }}
    >
      {" "}
      <SKParagraph centered={true} />
    </div>
  );
};

export default TestimonySlider;
