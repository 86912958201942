import React from "react";
import Skeleton from "react-loading-skeleton";

const SKParagraph = ({ centered = false }) => {
  const renderSkeletonParagraph = () => {
    const widths = [650, 600, 550, 450, 300];
    return widths.map((width, index) => (
      <div
        key={index}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Skeleton
          width={`${width}px`}
          height={20}
          style={{ marginBottom: "8px" }}
        />
      </div>
    ));
  };

  return (
    <div className="">
      {centered ? (
        renderSkeletonParagraph()
      ) : (
        <div>
          <Skeleton height={20} style={{ width: "100%" }} />
          <Skeleton height={20} style={{ width: "80%" }} />
          <Skeleton height={20} style={{ width: "70%" }} />
          <Skeleton height={20} style={{ width: "50%" }} />
        </div>
      )}
    </div>
  );
};

export default SKParagraph;
