import { useState } from "react";
import {
  FlutterWaveButton,
  useFlutterwave,
  closePaymentModal,
} from "flutterwave-react-v3";
import { Logo } from "../../assets";
import "./modal.css";

const Modal = ({ closeModal }) => {
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const config = {
    public_key: "FLWPUBK-a4fb863a33641bdc8ac0119e658480a0-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "GHS",
    payment_options: "card,mobilemoney,,ussd",
    customer: {
      email: email,
      phone_number: phone,
      name: name,
    },
    customizations: {
      title: "GUBA Payment",
      description: "Payment for GUBA items",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="closeBtn">
            <button onClick={() => closeModal(false)}>X</button>
          </div>

          <div className="header">
            <h1>Fill the form below to make payment</h1>
          </div>
          <div className="modalContent">
            <input
              type="number"
              placeholder="Amount"
              onChange={(e) => setAmount(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Phone"
              onChange={(e) => setPhone(e.target.value)}
            />

            <button
              onClick={() => {
                handleFlutterPayment({
                  callback: (response) => {
                    console.log(response);
                    closePaymentModal(); // this will close the modal programmatically
                  },
                  onClose: () => {},
                });
              }}
              className="btn-primary-variant"
              style={{ width: "100%" }}
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
