import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "./navbar.css";
import { Logo, logoWhite } from "./../../assets/index";
import { FaAlignRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { navigationLinks } from "./../../constants/links";

const Navbar = () => {
  const [isNavToggle, setIsNavToggle] = useState(false);

  const toggle = () => setIsNavToggle(!isNavToggle);
  const hide = () => setIsNavToggle(false);
  const show = () => setIsNavToggle(true);

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <Link to="/" className="nav__logo">
            <img loading="lazy" src={Logo} alt="Logo" className="logo" />
          </Link>
          <ul
            className={`nav__items ${isNavToggle ? "show__nav" : "hide__nav"}`}
          >
            {navigationLinks.map(({ name, url }, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={url}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={toggle}
                    onBlur={hide}
                    onFocus={show}
                  >
                    {name}
                  </NavLink>
                </li>
              );
            })}
            <NavLink
              to={"/joinus"}
              className={({ isActive }) =>
                isActive ? "active navBtnActive" : "navBtn"
              }
              onClick={toggle}
            >
              join
            </NavLink>
          </ul>

          <button
            id="open__nav-btn"
            onClick={() => setIsNavToggle(!isNavToggle)}
          >
            {isNavToggle ? <MdOutlineClose /> : <FaAlignRight />}
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
