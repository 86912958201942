import about from './about'
import blockContent from './blockContent'
import events from './events'
import gallery from './gallery'
import home from './home'
import homeSlider from './homeSlider'
import pastEvents from './pastEvents'
import services from './services'
import upcomingEvents from './upcomingEvents'
import videoGallery from './videoGallery'

export const schemaTypes = [
    home,
    about,
    homeSlider,
    services,
    events,
    pastEvents,
    upcomingEvents,
    gallery,
    videoGallery,
    blockContent
]
