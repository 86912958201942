// format date as 5th November 2023
export const formatLongDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    const dateObj = new Date(date);

    const day = dateObj.getUTCDate();

    const getSuffix = (day) => {
        if (day % 10 === 1 && day !== 11) return 'st';
        if (day % 10 === 2 && day !== 12) return 'nd';
        if (day % 10 === 3 && day !== 13) return 'rd';
        return 'th';
    };

    const dayStr = day + getSuffix(day);

    return dateObj.toLocaleDateString('en-GB', options).replace(/\d+/, dayStr);
};


// split date into day, month and year and return as an object
export const splitDate = (date) => {
    const dateObj = new Date(date);
    return {
        day: dateObj.getDate(),
        month: dateObj.toLocaleString('default', { month: 'long' }),
        year: dateObj.getFullYear(),
    };
}