export const additionalServices = [
    {
        value: "Introductions or meetings arranged with private and public sector offices",
        label: "Introductions or meetings arranged with private and public sector offices"
    },
    {
        value: "Charity Work & Initiatives through GUBA Foundation",
        label: "Charity Work & Initiatives through GUBA Foundation",
    },
    {
        value: "Trips, Tours and Experiences through GUBA Tours",
        label: "Trips, Tours and Experiences through GUBA Tours",
    },
    {
        value: "Additional meetings and research with the GUBA Diaspora Network team.",
        label: "Additional meetings and research with the GUBA Diaspora Network team."
    },
    {
        value: "Other – (choose this if you need more than one service)",
        label: "Other – (choose this if you need more than one service)"
    }
]