import React, { useState } from "react";
import { Notification } from "../index";
import "./modal.css";
import { SERVER_URL } from "../../utils/constant";

const BankInfoModal = ({ closeModal }) => {
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gdnid, setGdnid] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [bankName, setBankName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitButtonValue("Sending");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("gdnid", gdnid);
    formData.append("phone", phone);
    formData.append("country", country);
    formData.append("bankName", bankName);

    fetch(`${SERVER_URL}/request-bank-info`, {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);

        if (data.success) {
          // Log form data values
          // formData.forEach((value, key) => {
          //   console.log(key, value);
          // });
          console.log("Form submitted successfully!");

          setNotificationMessage(data.message);
          setNotificationType("success");

          setShowNotification(true);

          // Close the notification after 3 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);

          closeModal(true);

          window.location.assign("/payments");
        } else {
          console.error("Form submission failed:", data.error);

          setNotificationMessage(
            data.message || "Form submission failed. Please try again."
          );
          setNotificationType("error");

          // Show the notification
          setShowNotification(true);
        }

        setSubmitButtonValue("Submit");
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");

        // Show the notification
        setShowNotification(true);
        setSubmitButtonValue("Submit");
      });
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="closeBtn">
            <button onClick={() => closeModal(false)}>X</button>
          </div>

          <div className="header">
            <h1>Fill the form below</h1>
          </div>
          <div className="modalContent">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                name="name"
                placeholder="Fullname"
                required
              />
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                name="email"
                placeholder="Email"
                required
              />
              <input
                type="text"
                onChange={(e) => setGdnid(e.target.value)}
                value={gdnid}
                id="gdnid"
                name="gdnid"
                placeholder="DTN member ID (if any)"
              />
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="phone"
                name="phone"
                placeholder="Phone"
                required
              />
              <input
                type="text"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                id="country"
                name="country"
                placeholder="Country your sending the money from"
                required
              />
              <input
                type="text"
                onChange={(e) => setBankName(e.target.value)}
                value={bankName}
                id="bankName"
                name="bankName"
                placeholder="Bank Name"
                required
              />

              <input
                type="submit"
                className="btn-primary-variant"
                style={{ width: "100%", color: "#fff" }}
                value={submitButtonValue}
              />
            </form>
          </div>
        </div>
      </div>

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default BankInfoModal;
