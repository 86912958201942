import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./event-detail.css";
import { ScrollToTop, YoutubeFrame } from "../../components";
import { events, sliderTwoImages } from "../../constants/data";
import { SliderReversed } from "../../components";
import ImagesGallery from "../../components/Gallery";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";

const EventDetail = () => {
  const { eventId } = useParams();
  const [eventsData, setEventsData] = useState({});
  const [pastEvent, setPastEvent] = useState({});

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"]{..., pastEvents[] {..., image->, eventGallery[]-> {'url': image.asset->url}}, upcomingEvents[] {..., image->} }`
      )
      .then((data) => {
        setEventsData(data[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("eventsData", eventsData);
    let pastEvents = eventsData?.pastEvents;
    // set the current upcoming event
    let pastEvent_ = pastEvents?.find((event) => event._key === eventId);
    setPastEvent(pastEvent_);
  }, [eventsData]);

  useEffect(() => {
    console.log("pastEvent", pastEvent);
  }, [pastEvent]);

  return (
    <>
      <section className="eventDetailSection">
        <div className="container">
          <div className="eventHeader hor">
            <div className="left ver flex">
              <div className="eventTitle">
                <h1>{pastEvent?.title}</h1>
              </div>
            </div>
            <div className="right ver flex">
              <div className="imgWrapper">
                <img
                  src={urlFor(pastEvent?.image?.image)}
                  alt={pastEvent?.title}
                />
              </div>
            </div>
          </div>
          <div className="eventDetail">
            <p>{pastEvent?.description}</p>
          </div>
          {/* <div className="eventVideo">
            <YoutubeFrame link={pastEvent?.youtubeURL} />
          </div> */}
          <div className="eventPictures">
            {pastEvent?.eventGallery?.length > 0 && (
              <div className="header">
                <h3>Gallery</h3>
              </div>
            )}
            {pastEvent?.eventGallery?.length > 0 ? (
              <ImagesGallery galleryImages={pastEvent?.eventGallery} />
            ) : (
              <p>No images available</p>
            )}
          </div>
        </div>
      </section>
      <SliderReversed sliders={sliderTwoImages} />

      <ScrollToTop />
    </>
  );
};

export default EventDetail;
