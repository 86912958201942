import { defineType } from "sanity";

export default defineType({
    name: "upcomingEvents",
    title: "Upcoming Events",
    type: "object",
    fields: [
        {
            name: "title",
            title: "Title",
            type: "string",
        },
        {
            name: "image",
            title: "Image",
            type: "reference",
            to: [{ type: "gallery" }],
        },
        {
            name: "description",
            title: "Description",
            description: "A brief description of the event.",
            type: "text",
        },
        {
            name: "date",
            title: "Date",
            type: "datetime",
        },
        {
            name: "time",
            title: "Time",
            type: "string",
        },
        {
            name: "location",
            title: "Location",
            type: "string",
        },
        {
            name: "ticketLink",
            title: "Ticket Link",
            type: "url",
        }
    ],
    preview: {
        select: {
            title: "title",
            media: "image.image",
        },
    },
});