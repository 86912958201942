import React, { useEffect, useState } from "react";
import "./events.css";
import { ScrollToTop, EventCard, UpcomingEvents } from "../../components";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";

const Events = () => {
  const [eventsData, setEventsData] = useState({});

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"]{..., pastEvents[] {..., image->}, upcomingEvents[] {..., image->} }`
      )
      .then((data) => {
        setEventsData(data[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("eventsData", eventsData);
  }, [eventsData]);

  return (
    <>
      <section className="eventsSection">
        <div className="container">
          <div className="header">
            <h1>{eventsData?.title || <Skeleton height={65} width={300} />}</h1>
          </div>
          <div className="grayBackground">
            <UpcomingEvents
              title={eventsData?.upcomingEventsText}
              data={eventsData?.upcomingEvents}
            />
          </div>

          <EventCard
            title={eventsData?.pastEventsText}
            data={eventsData?.pastEvents}
          />
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Events;
