import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay, Pagination } from "swiper";

// import { Pagination, Navigation } from "swiper";
import { playIcon, vidSlide1, vidSlide2, vidSlide3 } from "../../assets";

import "./video_swiper.css";

const VideoSwiper = () => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <div className="vid_swipeWrapper">
        <Swiper
          slidesPerView={5}
          spaceBetween={350}
          loop={true}
          //   pagination={{
          //     clickable: true,
          //   }}
          breakpoints={{
            250: {
              slidesPerView: 2,
            },
            450: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          //   navigation={true}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 1800,
            disableOnInteraction: true,
          }}
          className="imgSwipe "
        >
          <div className="imgSlideContainer ">
            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={vidSlide1} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide ">
              <div className="imageHolder">
                <img src={vidSlide2} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={vidSlide3} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide ">
              <div className="imageHolder">
                <img src={vidSlide2} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={vidSlide1} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={vidSlide3} alt="" className="slide__image" />
              </div>
              <div class="play__btn">
                <img src={playIcon} alt="" />
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default VideoSwiper;
