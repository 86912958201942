import React, { useEffect, useState } from "react";
import "./header.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SwiperCore, { Autoplay } from "swiper";

import { Navigation, Pagination } from "swiper";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import LoadingSpinner from "../LoadingSpinner";

const HeaderSwiper = () => {
  SwiperCore.use([Autoplay]);

  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "homeSlider"]{..., "backgroundVideoUrl": backgroundVideo->video.asset->url}`
      )
      .then((data) => {
        setSliderData(data);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("sliderData: ", sliderData);
  }, [sliderData]);

  return (
    <>
      <header>
        <div className="headerSwiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}
            className="vidSwipe"
          >
            <div className="swiper-wrapper">
              {loading ? (
                <LoadingSpinner />
              ) : (
                sliderData?.map((slide, index) => (
                  <SwiperSlide key={index} className="headSlide">
                    <div className="swiper-video">
                      <video autoPlay loop muted playsInline id="hero-bgVid">
                        <source
                          src={slide.backgroundVideoUrl}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="caption" data-count="04">
                      <div className="container">
                        <h1 className="header-5" data-text={slide?.title}>
                          {slide?.title || <Skeleton height={65} width={400} />}
                        </h1>
                        <p>
                          {slide?.description || (
                            <Skeleton count={2} width={500} />
                          )}
                        </p>
                        <Link to="./aboutus" className="btn2">
                          {slide?.aboutText || "ABOUT US"}
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              )}
            </div>
          </Swiper>
        </div>
      </header>
    </>
  );
};

export default HeaderSwiper;
