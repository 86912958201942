import { RouterProvider } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import router from "./router";

function App() {
  return (
    <div className="App">
      <SkeletonTheme baseColor="#d7d7d7" highlightColor="#bcbcbc">
        <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
}

export default App;
