import React, { useEffect, useState } from "react";
import "./about.css";
import { SKParagraph, ScrollToTop, Slider } from "../../components";
import { sliderImages, sliderTwoImages } from "../../constants/data";
import { aim, blob, calender, initiative } from "../../assets";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import SkCardText from "../../components/Skeletons/SKCardText";
import BlockContent from "@sanity/block-content-to-react";

const AboutUs = () => {
  const [aboutData, setAboutData] = useState({});

  const firstWord = (text) => {
    return text.split(" ")[0] + " ";
  };

  const secondWord = (text) => {
    return text.split(" ")[1] + " ";
  };

  const lastWordsAfterTheSecondWord = (text) => {
    return text.split(" ").slice(2).join(" ");
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "about"]`)
      .then((data) => {
        setAboutData(data[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("about data: ", aboutData);
  }, [aboutData]);

  return (
    <>
      <section id="pastevents" className="pastevents">
        <div className="container">
          <div className="about__content">
            <h1>
              {aboutData?.aboutTitle || <Skeleton height={65} width={300} />}
            </h1>
            <p>
              {aboutData?.aboutDescription || <SKParagraph centered={true} />}
            </p>
          </div>
          <h2>Our Past.... Our Present.... Our Future</h2>
          <div style={{ width: "100%" }}>
            <p>{aboutData?.pastPresentFutureText || <SKParagraph />}</p>
          </div>
        </div>
        <Slider sliders={sliderImages} />
        <div className="container cards-container">
          <div className="cards">
            <div className="card">
              <p
                style={{
                  width: `${aboutData?.pastText ? "fit-content" : "100%"}`,
                }}
              >
                {aboutData?.pastText || <SkCardText />}
              </p>
              <a href="#about" className="btn-primary-variant">
                The Past
              </a>
            </div>
            <div className="card">
              <p
                style={{
                  width: `${aboutData?.presentText ? "fit-content" : "100%"}`,
                }}
              >
                {aboutData?.presentText || <SkCardText />}
              </p>
              <a href="#about" className="btn-primary-variant">
                The Present
              </a>
            </div>
            <div className="card">
              <p
                style={{
                  width: `${aboutData?.futureText ? "fit-content" : "100%"}`,
                }}
              >
                {aboutData?.futureText || <SkCardText />}
              </p>
              <a href="#about" className="btn-primary-variant">
                The Future
              </a>
            </div>
          </div>
        </div>
        <div className="container what-we-do">
          <div className="whatwedo-content">
            <h1>
              {aboutData?.whatWeDoTitle || <Skeleton height={65} width={300} />}
            </h1>
            {/* <p>
              The GUBA Diaspora Network supports diasporans and individuals of
              various heritages who are seeking to connect, transition, do
              business or invest in Ghana. Through our membership initiative the
              GUBA Diaspora Network supports diasporans in Ghana as well as
              those in UK, Europe, USA, Canada and different countries across
              the world.
              <br />
              <br />
              We provide a helping hand to our members by through an initial
              1-2-1 session to establish their needs with a detailed write up of
              information, guidance and support to direct their next steps. We
              hold regular social networking meet ups in Accra, virtual
              discussions with Industry professionals and organisations sharing
              knowledge on areas impacting diasporans such as Real Estate, Land
              Acquisition, Immigration, Health Service and more.
              <br />
              <br />
              The Diaspora Conversations is our annual conference which brings
              together the governmental regulatory authorities in Ghana and
              diasporans sharing their experiences in an event to help
              understand the systems in place in Ghana.
              <br />
              <br />
              As a network we organize events to bring the community together,
              develop give back initiatives and assist members where the need
              arises. We encourage members to connect and through our skills
              database are able to introduce those within specific industries to
              each other.
            </p> */}
            <BlockContent blocks={aboutData?.whatWeDoDescription} />
            <a href="services.php" className="btn-primary-variant">
              Learn more
            </a>
          </div>
        </div>
      </section>
      <section id="aims">
        <div className="container">
          <div className="left">
            <h1>
              {aboutData?.aimsTitle || <Skeleton height={65} width={300} />}
            </h1>
            <p>
              {aboutData?.aimsIntroText || "Loading..."}
              <ul>
                {aboutData?.aimsList?.map((item, index) => (
                  <li key={index}>
                    {firstWord(item.aimText)}
                    <i>
                      <b>{secondWord(item.aimText)}</b>
                    </i>
                    {lastWordsAfterTheSecondWord(item.aimText)}
                  </li>
                )) || <SKParagraph />}
              </ul>
            </p>
          </div>
          <div className="right">
            <div className="image-container">
              <div className="blob">
                <img src={blob} alt="" />
              </div>
              <div className="overlay-img">
                <img src={aim} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="initiatives">
        <div className="container">
          <div className="left">
            <div className="image-container">
              <div className="blob">
                <img src={initiative} alt="" />
              </div>
            </div>
          </div>
          <div className="right">
            <h1>
              {aboutData?.initiativesTitle || (
                <Skeleton height={65} width={300} />
              )}
            </h1>
            <ul style={{ width: !aboutData?.initiativesList && "100%" }}>
              {aboutData?.initiativesList?.map((item, index) => (
                <li key={index}>{item.initiativeText}</li>
              )) || <SKParagraph />}
            </ul>
            <a href="initiatives.php" className="btn-primary-variant">
              Learn more
            </a>
          </div>
        </div>
      </section>
      <section id="events">
        <div className="container">
          <div className="left">
            <h1>
              {aboutData?.eventsTitle || <Skeleton height={65} width={300} />}
            </h1>
            <p>{aboutData?.eventsDescription || <SKParagraph />}</p>
            <a href="events.php" className="btn-primary-variant">
              Learn more
            </a>
          </div>
          <div className="right">
            <div className="image-container">
              <div className="blob">
                <img src={calender} alt="" />
              </div>
            </div>
          </div>
        </div>
        <Slider sliders={sliderTwoImages} />
        <ScrollToTop />
      </section>
    </>
  );
};

export default AboutUs;
