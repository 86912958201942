import { defineField, defineType } from "sanity";

export default defineType({
    name: "about",
    title: "About Page",
    type: "document",
    fields: [
        defineField({
            name: "aboutTitle",
            title: "About Title",
            type: "string"
        }),
        defineField({
            name: "aboutDescription",
            title: "About Description",
            type: "text",
            rows: 4,
        }),
        defineField({
            name: "pastPresentFutureText",
            title: "Past Present Future Text",
            type: "text",
            rows: 4,
        }),
        defineField({
            name: "pastText",
            title: "Past Text",
            type: "text",
            rows: 4,
        }),
        defineField({
            name: "presentText",
            title: "Present Text",
            type: "text",
            rows: 4,
        }),
        defineField({
            name: "futureText",
            title: "Future Text",
            type: "text",
            rows: 4,
        }),
        defineField({
            name: "whatWeDoTitle",
            title: "\'What We Do\' Title",
            type: "string"
        }),
        defineField({
            name: "whatWeDoDescription",
            title: "\'What We Do\' Description",
            type: "blockContent",
        }),
        defineField({
            name: "aimsTitle",
            title: "Aims Title",
            type: "string"
        }),
        defineField({
            name: "aimsIntroText",
            title: "Aims Intro Text",
            type: "text",
            rows: 3,
        }),
        defineField({
            name: "aimsList",
            title: "Aims List",
            type: "array",
            description: "The list of aims for GDN",
            of: [{
                type: "object",
                fields: [
                    defineField({
                        name: "aimText",
                        title: "Aim Description",
                        type: "text",
                        description: "The description for the aim",
                        rows: 3
                    })
                ],
                preview: {
                    select: {
                        title: "aimText"
                    }
                }
            }]
        }),
        defineField({
            name: "initiativesTitle",
            title: "Initiatives Title",
            type: "string",
        }),
        defineField({
            name: "initiativesList",
            title: "Initiatives List",
            type: "array",
            description: "The list of initiatives for GDN",
            of: [{
                type: "object",
                fields: [
                    defineField({
                        name: "initiativeText",
                        title: "Initiative Description",
                        type: "text",
                        description: "The description for the initiative",
                        rows: 3
                    })
                ],
                preview: {
                    select: {
                        title: "initiativeText"
                    }
                }
            }]
        }),
        defineField({
            name: "eventsTitle",
            title: "Events Title",
            type: "string"
        }),
        defineField({
            name: "eventsDescription",
            title: "Events Description",
            type: "text",
            rows: 4
        })
    ]
})