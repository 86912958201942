import { defineField, defineType } from "sanity";

export default defineType({
    name: "home",
    title: "Home",
    type: "document",
    fields: [
        defineField({
            name: "welcomeTitle",
            title: "Welcome Title",
            type: "string"
        }),
        defineField({
            name: "welcomeDescription",
            title: "Welcome Description",
            description: "This is the text under the \'Welcome\' title",
            type: "text",
            rows: 4
        }),
        defineField({
            name: "aboutTitle",
            title: "About Title",
            type: "string"
        }),
        defineField({
            name: "aboutDescription",
            title: "About Description",
            description: "This is the text under the \'About Us\' title",
            type: "text",
            rows: 4
        }),
        defineField({
            name: "missionCards",
            title: "Mission Cards",
            description: "These are the cards coming right after the \'About Us\' section",
            type: "array",
            of: [{
                type: "object",
                fields: [
                    defineField({
                        name: "icon",
                        title: "Icon",
                        type: "image"
                    }),
                    defineField({
                        name: "description",
                        title: "Description",
                        type: "text",
                        rows: 3
                    })
                ],
                preview: {
                    select: {
                        media: "icon.image",
                        title: "description"
                    }
                }
            }]
        }),
        defineField({
            name: "firstSlideImages",
            title: "First Sliding Images",
            description: "These are the images that appear as a slider under the Mission Cards",
            type: "array",
            of: [{
                type: "reference",
                to: [{ type: "gallery" }]
            }]
        }),
        defineField({
            name: "communityTitle",
            title: "Our Community Title",
            type: "string"
        }),
        defineField({
            name: "communityDescription",
            title: "Our Community Description",
            description: "This is the text under the \'Our Community\' title",
            type: "text",
            rows: 4
        }),
        defineField({
            name: "testimonialsTitle",
            title: "Testimonials Title",
            type: "string"
        }),
        defineField({
            name: "testimonyMessages",
            title: "Testimonies",
            description: "These are the testimonies that appear as a slider under the \'Testimonials\' title",
            type: "array",
            of: [{
                type: "object",
                fields: [
                    defineField({
                        name: "author",
                        title: "Author",
                        type: "string"
                    }),
                    defineField({
                        name: "message",
                        title: "Message",
                        type: "text",
                        rows: 3
                    }),
                ],
                preview: {
                    select: {
                        title: "message",
                        subtitle: "author"
                    }
                }
            }]
        }),
        defineField({
            name: "enterpriseTitle",
            title: "Enterprise Title",
            type: "string"
        }),
        defineField({
            name: "enterpriseDescription",
            title: "Enterprise Description",
            description: "This is the text under the \'GUBA Enterprise\' title",
            type: "text",
            rows: 4
        }),
        defineField({
            name: "secondSlideImages",
            title: "Second Slide Images",
            description: "These are the images that appear as a slider under the text description of \'GUBA Enterprise\'",
            type: "array",
            of: [{
                type: "reference",
                to: [{ type: "gallery" }]
            }]
        }),
        defineField({
            name: "ourPartnersTitle",
            title: "Our Partners Title",
            type: "string"
        }),
        defineField({
            name: "ourPartnersDescription",
            title: "Our Partners Description",
            description: "This is the text under the \'Our Partners\' title",
            type: "text",
            rows: 4
        }),
        defineField({
            name: "partners",
            title: "Partners",
            description: "These are the logos of the partners",
            type: "array",
            of: [{
                type: "image"
            }]
        })
    ]
})