import React from "react";
import "./join-us.css";
import { JoinForm, ScrollToTop } from "../../components";

const JoinUs = () => {
  return (
    <>
      <section id="joinus" className="joinus">
        <div className="container">
          <div className="top">
            <h2>The GUBA Diaspora Network Registration Form</h2>
            <small>Step 1 = Complete Registration Form</small>
            <p>
              The GUBA Diaspora Network (GDN), a subsidiary of the GUBA
              Enterprise brand is a membership-based network for African
              Diasporans and returnees of various heritages with a desire to
              connect, transition or invest in Ghana. This network is a
              community for information sharing, skills exchange, cultural
              awareness and a space for doing and advertising business.
            </p>
            <div>
              <h3>The GUBA Diaspora Network aims:</h3>
              <ul>
                <li>
                  To provide assistance on all business and relocation issues
                  faced by Diasporans
                </li>
                <li>
                  To generate solutions to the challenges faced by the diaspora
                  in Ghana
                </li>
                <li>
                  To promote the businesses of members to other Diasporans
                </li>
                <li>To form a community of impact</li>
              </ul>
            </div>
            <div>
              <h3>
                The following steps will need to be completed to become a
                member:
              </h3>
              <ul className="bolder-list">
                <li>Step 1 = Complete Registration Form</li>
                <li>Step 2 = Make payment for the year</li>
                <li>Step 3 = Be added to the GDN community</li>
                <li>Step 3 = One on One meeting with a GDN team member.</li>
              </ul>
            </div>
            <p>
              You will then be given the opportunity to have One to One meeting
              with a member of the team to explore your specific needs and
              support you in your transition.
              <br />
              <br />
              We look forward to having you on board!
            </p>
          </div>
          <div className="forms">
            <JoinForm />
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default JoinUs;
