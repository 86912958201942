import React from "react";
import Skeleton from "react-loading-skeleton";
import SKParagraph from "../SKParagraph";
import "react-loading-skeleton/dist/skeleton.css";
import "./skmission.css";

const SKMissionCards = () => {
  return (
    <div id="skmission">
      <div className="vmStatements">
        <div className="statements">
          {Array.from({ length: 6 }).map((_, id) => (
            <div className="statementBars" key={id}>
              <div className="iconBox">
                <Skeleton width={80} height={80} />
              </div>
              <div className="txtBox">
                <p>
                  <Skeleton width={320} height={20} />
                  <Skeleton width={280} height={20} />
                  <Skeleton width={180} height={20} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SKMissionCards;
